import React, { useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FaLightbulb, FaStar, FaClock, FaChartLine, FaUsers, FaMobileAlt } from 'react-icons/fa';

const facts = [
  {
    title: "Review Impact",
    content: "94% of consumers say a negative review has convinced them to avoid a business",
    icon: FaStar,
    color: "text-yellow-500"
  },
  {
    title: "Response Time",
    content: "Businesses that respond to reviews 25% faster see 35% more revenue growth",
    icon: FaClock,
    color: "text-blue-500"
  },
  {
    title: "Growth Rate",
    content: "Businesses actively managing reviews grow 18% faster year-over-year",
    icon: FaChartLine,
    color: "text-green-500"
  },
  {
    title: "Customer Trust",
    content: "97% of consumers read reviews before visiting a local business",
    icon: FaUsers,
    color: "text-purple-500"
  },
  {
    title: "Mobile Search",
    content: "78% of local mobile searches lead to offline purchases within 24 hours",
    icon: FaMobileAlt,
    color: "text-red-500"
  }
];

export default function DidYouKnow() {
  const { theme } = useTheme();
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className={`py-20 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gradient-to-br from-blue-50 via-indigo-50 to-white'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className={`inline-block p-3 rounded-lg mb-6 ${theme === 'dark' ? 'bg-gray-700' : 'bg-white/80 backdrop-blur-sm'} shadow-lg`}>
            <FaLightbulb className={`text-4xl ${theme === 'dark' ? 'text-yellow-400' : 'text-yellow-500'} animate-pulse`} />
          </div>
          <h2 className={`text-4xl md:text-5xl font-bold mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Did You Know?
          </h2>
          <p className={`text-xl md:text-2xl max-w-3xl mx-auto ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            Discover how online reviews are reshaping the business landscape
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {facts.map((fact, index) => {
            const Icon = fact.icon;
            return (
              <div
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                className={`relative p-8 rounded-xl ${
                  theme === 'dark' ? 'bg-gray-700 hover:bg-gray-600' : 'bg-white hover:bg-blue-50'
                } shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 group`}
              >
                <div className={`absolute top-0 right-0 w-24 h-24 ${fact.color} opacity-5 rounded-bl-full`} />
                <div className={`flex items-center justify-center mb-6 ${fact.color}`}>
                  <Icon className={`text-4xl transition-transform duration-300 ${hoveredIndex === index ? 'scale-125' : ''}`} />
                </div>
                <h3 className={`text-2xl font-bold mb-4 text-center ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {fact.title}
                </h3>
                <p className={`text-lg text-center leading-relaxed ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                  {fact.content}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
