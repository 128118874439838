import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import App from './App';
import Hero from './components/Hero';
import Stats from './components/Stats';
import Features from './components/Features';
import BeforeAfter from './components/BeforeAfter';
import RecentWork from './components/RecentWork';
import FeatureShowcase from './components/FeatureShowcase';
import Testimonials from './components/Testimonials';
import BlogSection from './components/BlogSection';
import CallToAction from './components/CallToAction';
import DidYouKnow from './components/DidYouKnow';
import FeaturesPage from './pages/Features';
import PricingPage from './pages/Pricing';
import BlogPage from './pages/Blog';
import BlogPost from './pages/BlogPost';
import BlogTag from './pages/BlogTag';
import ReviewCalculator from './pages/ReviewCalculator';
import ReviewChecker from './pages/ReviewChecker';
import ThankYou from './pages/ThankYou';
import TrialSignup from './pages/TrialSignup';
import Reviews from './pages/Reviews';
import CaseStudies from './pages/CaseStudies';
import About from './pages/About';
import Contact from './pages/Contact';
import Help from './pages/Help';
import ErrorPage from './pages/ErrorPage';
import GDPR from './pages/GDPR';
import Affiliates from './pages/Affiliates';
import Support from './pages/Support';
import Community from './pages/Community';
import Documentation from './pages/Documentation';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import CookiePolicy from './pages/CookiePolicy';
import Cookies from './pages/Cookies';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import LuxuryHotel from './pages/case-studies/LuxuryHotel';
import Restaurant from './pages/case-studies/Restaurant';
import MedicalPractice from './pages/case-studies/MedicalPractice';

const HomePage = () => (
  <>
    <Hero />
    <DidYouKnow />
    <Features />
    <BeforeAfter />
    <RecentWork />
    <FeatureShowcase />
    <Testimonials />
    <BlogSection />
    <CallToAction />
  </>
);

const routerConfig = {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true
  }
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      <Route index element={<HomePage />} />
      <Route path="features" element={<FeaturesPage />} />
      <Route path="pricing" element={<PricingPage />} />
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="blog">
        <Route index element={<BlogPage />} />
        <Route path=":id" element={<BlogPost />} />
        <Route path="tag/:tag" element={<BlogTag />} />
      </Route>
      <Route path="reviews" element={<Reviews />} />
      <Route path="case-studies">
        <Route index element={<CaseStudies />} />
        <Route path="luxury-hotel" element={<LuxuryHotel />} />
        <Route path="restaurant" element={<Restaurant />} />
        <Route path="medical-practice" element={<MedicalPractice />} />
      </Route>
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="help" element={<Help />} />
      <Route path="review-calculator" element={<ReviewCalculator />} />
      <Route path="review-checker" element={<ReviewChecker />} />
      <Route path="thank-you" element={<ThankYou />} />
      <Route path="trial-signup" element={<TrialSignup />} />
      <Route path="gdpr" element={<GDPR />} />
      <Route path="affiliates" element={<Affiliates />} />
      <Route path="support" element={<Support />} />
      <Route path="community" element={<Community />} />
      <Route path="docs" element={<Documentation />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="terms-of-service" element={<TermsOfService />} />
      <Route path="cookie-policy" element={<CookiePolicy />} />
      <Route path="cookies" element={<Cookies />} />
    </Route>
  ),
  routerConfig
);
