import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FaArrowRight, FaChartLine, FaStar, FaUsers } from 'react-icons/fa';

const CaseStudies = () => {
  const { theme } = useTheme();

  const caseStudies = [
    {
      id: 1,
      company: "Global Eats Restaurant Chain",
      industry: "Restaurant & Hospitality",
      challenge: "Managing reviews across 500+ locations with inconsistent response times and quality.",
      solution: "Implemented 5StarHQ's AI-powered review management system across all locations.",
      results: [
        "90% reduction in response time",
        "35% increase in positive reviews",
        "Standardized review responses across all locations"
      ],
      metrics: {
        reviewIncrease: "35%",
        responseTime: "2hrs",
        satisfaction: "95%"
      }
    },
    {
      id: 2,
      company: "TechPro Services",
      industry: "B2B Technology",
      challenge: "Struggling to maintain brand reputation across multiple review platforms.",
      solution: "Utilized 5StarHQ's sentiment analysis and automated response suggestions.",
      results: [
        "50% improvement in review ratings",
        "Automated response to 80% of reviews",
        "Increased customer engagement by 40%"
      ],
      metrics: {
        reviewIncrease: "50%",
        responseTime: "1hr",
        satisfaction: "98%"
      }
    },
    {
      id: 3,
      company: "HealthFirst Clinics",
      industry: "Healthcare",
      challenge: "Difficulty managing sensitive patient feedback across multiple clinics.",
      solution: "Implemented 5StarHQ's HIPAA-compliant review management system.",
      results: [
        "100% HIPAA compliance in responses",
        "45% increase in patient satisfaction",
        "Reduced negative reviews by 60%"
      ],
      metrics: {
        reviewIncrease: "45%",
        responseTime: "3hrs",
        satisfaction: "92%"
      }
    }
  ];

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
        <div className="text-center">
          <h1 className={`text-4xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Customer Success Stories
          </h1>
          <p className={`text-xl mb-8 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            See how businesses are transforming their review management with 5StarHQ
          </p>
        </div>

        {/* Case Studies */}
        <div className="mt-12 space-y-12">
          {caseStudies.map((study) => (
            <div
              key={study.id}
              className={`rounded-lg shadow-lg overflow-hidden ${
                theme === 'dark' ? 'bg-gray-800' : 'bg-white'
              }`}
            >
              <div className="p-8">
                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
                  <div>
                    <h2 className={`text-2xl font-bold mb-2 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                      {study.company}
                    </h2>
                    <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                      {study.industry}
                    </p>
                  </div>
                  <div className="mt-4 lg:mt-0 flex space-x-8">
                    <div className="text-center">
                      <p className="text-blue-500 text-2xl font-bold">{study.metrics.reviewIncrease}</p>
                      <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>Review Increase</p>
                    </div>
                    <div className="text-center">
                      <p className="text-green-500 text-2xl font-bold">{study.metrics.responseTime}</p>
                      <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>Response Time</p>
                    </div>
                    <div className="text-center">
                      <p className="text-yellow-500 text-2xl font-bold">{study.metrics.satisfaction}</p>
                      <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>Satisfaction</p>
                    </div>
                  </div>
                </div>

                <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-8">
                  <div>
                    <h3 className={`font-semibold mb-2 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                      Challenge
                    </h3>
                    <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
                      {study.challenge}
                    </p>
                  </div>
                  <div>
                    <h3 className={`font-semibold mb-2 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                      Solution
                    </h3>
                    <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
                      {study.solution}
                    </p>
                  </div>
                  <div>
                    <h3 className={`font-semibold mb-2 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                      Results
                    </h3>
                    <ul className={`text-sm space-y-1 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
                      {study.results.map((result, index) => (
                        <li key={index} className="flex items-center">
                          <FaArrowRight className="text-blue-500 mr-2" />
                          {result}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Call to Action */}
        <div className="mt-16 text-center">
          <h2 className={`text-2xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Ready to Transform Your Review Management?
          </h2>
          <button
            className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors duration-200"
          >
            Start Your Success Story
          </button>
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
