import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FaStar, FaArrowRight, FaChartLine } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const caseStudies = [
  {
    id: 1,
    title: "Luxury Hotel Chain",
    description: "Transformed their review response strategy, leading to a 45% increase in positive reviews",
    metrics: [
      { label: "Review Score Increase", value: "+1.8", icon: "" },
      { label: "Response Rate", value: "98%", icon: "" },
      { label: "Customer Satisfaction", value: "+40%", icon: "" }
    ],
    image: "https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    category: "Hospitality",
    improvement: "4.2 → 4.8",
    testimonial: {
      text: "5StarHQ helped us maintain our luxury reputation across all review platforms.",
      author: "Emma Thompson",
      position: "Guest Relations Manager"
    }
  },
  {
    id: 2,
    title: "Restaurant Group",
    description: "Automated review monitoring across 12 locations, saving 20+ hours per week",
    metrics: [
      { label: "Time Saved Weekly", value: "20hrs", icon: "" },
      { label: "Response Time", value: "-85%", icon: "" },
      { label: "New Reviews/Month", value: "+60%", icon: "" }
    ],
    image: "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    category: "Restaurant",
    improvement: "3.8 → 4.6",
    testimonial: {
      text: "The AI-powered responses have been a game-changer for our multi-location business.",
      author: "David Chen",
      position: "Operations Director"
    }
  },
  {
    id: 3,
    title: "Medical Practice",
    description: "Improved patient satisfaction and online reputation with personalized review responses",
    metrics: [
      { label: "Patient Satisfaction", value: "+35%", icon: "" },
      { label: "Review Volume", value: "+125%", icon: "" },
      { label: "Sentiment Score", value: "+28%", icon: "" }
    ],
    image: "https://images.unsplash.com/photo-1519494026892-80bbd2d6fd0d?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80",
    category: "Healthcare",
    improvement: "3.9 → 4.7",
    testimonial: {
      text: "Our online reputation now truly reflects the quality of care we provide.",
      author: "Dr. Sarah Miller",
      position: "Practice Owner"
    }
  }
];

export default function RecentWork() {
  const { theme } = useTheme();
  const navigate = useNavigate();

  const handleCaseStudyClick = (id) => {
    switch(id) {
      case 1:
        navigate('/case-studies/luxury-hotel');
        break;
      case 2:
        navigate('/case-studies/restaurant');
        break;
      case 3:
        navigate('/case-studies/medical-practice');
        break;
      default:
        break;
    }
  };

  return (
    <section className={`py-24 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-20">
          <h2 className={`text-4xl md:text-5xl font-extrabold mb-6 tracking-tight leading-tight ${
            theme === 'dark' ? 'text-white' : 'text-gray-900'
          }`}>
            Our Recent Success Stories
          </h2>
          <p className={`text-xl md:text-2xl font-light leading-relaxed max-w-3xl mx-auto ${
            theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
          }`}>
            See how we've helped businesses transform their online reputation
          </p>
        </div>

        {/* Case Studies Grid */}
        <div className="grid md:grid-cols-3 gap-10">
          {caseStudies.map((study) => (
            <article 
              key={study.id}
              className={`case-study-card rounded-2xl overflow-hidden ${
                theme === 'dark' ? 'bg-gray-700' : 'bg-white'
              }`}
            >
              {/* Image Section */}
              <div className="relative h-56 overflow-hidden">
                <img
                  src={study.image}
                  alt={study.title}
                  className="case-study-image w-full h-full object-cover"
                />
                <div className="absolute top-4 left-4">
                  <span className={`px-4 py-2 rounded-full text-sm font-semibold tracking-wide shadow-lg text-white ${
                    theme === 'dark' ? 'bg-blue-600' : 'bg-blue-500'
                  }`}>
                    {study.category}
                  </span>
                </div>
              </div>

              {/* Content Section */}
              <div className="p-8">
                <h3 className={`text-2xl font-bold mb-4 leading-tight ${
                  theme === 'dark' ? 'text-white' : 'text-gray-900'
                }`}>
                  {study.title}
                </h3>
                <p className={`text-lg mb-6 leading-relaxed ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  {study.description}
                </p>

                {/* Metrics Grid */}
                <div className="grid grid-cols-3 gap-6 mb-8">
                  {study.metrics.map((metric, idx) => (
                    <div key={idx} className="case-study-metric">
                      <div className="case-study-metric-icon">{metric.icon}</div>
                      <div className={`case-study-metric-value ${
                        theme === 'dark' ? 'text-blue-400' : 'text-blue-600'
                      }`}>
                        {metric.value}
                      </div>
                      <div className={`case-study-metric-label ${
                        theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        {metric.label}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Review Score Section */}
                <div className={`flex items-center justify-between p-6 rounded-xl mb-8 ${
                  theme === 'dark' ? 'bg-gray-600' : 'bg-gray-50'
                }`}>
                  <div>
                    <div className={`text-sm font-medium mb-1 ${
                      theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
                    }`}>
                      Review Score Improvement
                    </div>
                    <div className={`text-2xl font-bold flex items-center gap-2 ${
                      theme === 'dark' ? 'text-white' : 'text-gray-900'
                    }`}>
                      {study.improvement}
                      <FaChartLine className="text-green-500" />
                    </div>
                  </div>
                  <div className="flex text-yellow-400 text-xl gap-1">
                    {[...Array(5)].map((_, i) => (
                      <FaStar key={i} />
                    ))}
                  </div>
                </div>
              </div>

              {/* Testimonial Section */}
              <div className={`testimonial-section ${
                theme === 'dark' ? 'bg-gray-600 border-gray-600' : 'bg-gray-50 border-gray-200'
              }`}>
                <blockquote className={`testimonial-text ${
                  theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
                }`}>
                  "{study.testimonial.text}"
                </blockquote>
                <div>
                  <p className={`testimonial-author ${
                    theme === 'dark' ? 'text-white' : 'text-gray-900'
                  }`}>
                    {study.testimonial.author}
                  </p>
                  <p className={`testimonial-position ${
                    theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    {study.testimonial.position}
                  </p>
                </div>
              </div>

              {/* Call to Action */}
              <div className="p-8">
                <button
                  onClick={() => handleCaseStudyClick(study.id)}
                  className={`w-full flex items-center justify-center space-x-3 px-8 py-4 rounded-xl text-lg font-semibold transition-all duration-200 hover:shadow-lg text-white ${
                    theme === 'dark' ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
                  }`}
                >
                  <span>View Full Case Study</span>
                  <FaArrowRight className="text-sm" />
                </button>
              </div>
            </article>
          ))}
        </div>

        {/* Bottom Call to Action */}
        <div className="text-center mt-20">
          <p className={`text-xl font-light mb-8 ${
            theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
          }`}>
            Ready to transform your online reputation?
          </p>
          <button
            onClick={() => navigate('/trial-signup')}
            className={`inline-flex items-center space-x-3 px-10 py-5 rounded-xl text-xl font-semibold transition-all duration-200 hover:shadow-xl hover:scale-105 text-white ${
              theme === 'dark' ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            <span>Start Your Success Story</span>
            <FaArrowRight />
          </button>
        </div>
      </div>
    </section>
  );
}
