// Test deployment trigger - FeatureShowcase Component
import { useState } from 'react'
import { FaEnvelope, FaComments, FaRobot, FaChartBar, FaShareAlt, FaGlobe } from 'react-icons/fa'

export default function FeatureShowcase() {
  const [activeFeature, setActiveFeature] = useState(0)

  const features = [
    {
      icon: <FaEnvelope className="w-8 h-8" />,
      title: "Smart Review Invitations",
      description: "Automatically send personalized review invitations at the perfect moment.",
      image: "https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&w=800&q=80"
    },
    {
      icon: <FaComments className="w-8 h-8" />,
      title: "Review Monitoring",
      description: "Track all your reviews in real-time across multiple platforms.",
      image: "https://images.unsplash.com/photo-1551434678-e076c223a692?auto=format&fit=crop&w=800&q=80"
    },
    {
      icon: <FaRobot className="w-8 h-8" />,
      title: "AI-Powered Responses",
      description: "Generate perfect responses to reviews in seconds with our AI.",
      image: "https://images.unsplash.com/photo-1535378917042-10a22c95931a?auto=format&fit=crop&w=800&q=80"
    }
  ]

  return (
    <div className="py-20 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-8">
            <h2 className="section-title">Powerful Features</h2>
            <div className="space-y-4">
              {features.map((feature, index) => (
                <div
                  key={index}
                  className={`p-6 rounded-xl cursor-pointer transition-all duration-300 ${
                    activeFeature === index
                      ? 'bg-blue-600 dark:bg-blue-500 text-white transform scale-105'
                      : 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700'
                  }`}
                  onClick={() => setActiveFeature(index)}
                >
                  <div className="flex items-center space-x-4">
                    <div className={`${
                      activeFeature === index ? 'text-white' : 'text-blue-600 dark:text-blue-400'
                    }`}>
                      {feature.icon}
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg">{feature.title}</h3>
                      <p className={`${
                        activeFeature === index ? 'text-blue-100' : 'text-gray-600 dark:text-gray-300'
                      }`}>
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="relative h-[600px] rounded-2xl overflow-hidden">
            {features.map((feature, index) => (
              <div
                key={index}
                className={`absolute inset-0 transition-opacity duration-500 ${
                  activeFeature === index ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-full h-full object-cover rounded-2xl"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
