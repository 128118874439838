import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FaHeadset, FaComments, FaEnvelope, FaBook } from 'react-icons/fa';

const Support = () => {
  const { theme } = useTheme();

  const supportChannels = [
    {
      icon: <FaHeadset className="text-3xl" />,
      title: "24/7 Support",
      description: "Our support team is available around the clock to help you with any issues.",
      action: "Contact Support",
      link: "mailto:support@5starhq.com"
    },
    {
      icon: <FaComments className="text-3xl" />,
      title: "Live Chat",
      description: "Get instant help during business hours through our live chat system.",
      action: "Start Chat",
      link: "#chat"
    },
    {
      icon: <FaEnvelope className="text-3xl" />,
      title: "Email Support",
      description: "Send us an email and we'll get back to you within 24 hours.",
      action: "Send Email",
      link: "mailto:help@5starhq.com"
    },
    {
      icon: <FaBook className="text-3xl" />,
      title: "Knowledge Base",
      description: "Browse our extensive documentation and tutorials.",
      action: "View Docs",
      link: "/docs"
    }
  ];

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="relative py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className={`text-4xl md:text-5xl font-bold mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            How Can We Help You?
          </h1>
          <p className={`text-xl mb-8 max-w-3xl mx-auto ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            We're here to help you get the most out of 5StarHQ. Choose your preferred support channel below.
          </p>
        </div>
      </div>

      {/* Support Channels */}
      <div className={`py-16 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {supportChannels.map((channel, index) => (
              <div
                key={index}
                className={`p-6 rounded-lg ${
                  theme === 'dark' ? 'bg-gray-700' : 'bg-white'
                } flex flex-col items-center text-center`}
              >
                <div className={`mb-4 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {channel.icon}
                </div>
                <h3 className={`text-xl font-semibold mb-2 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {channel.title}
                </h3>
                <p className={`mb-6 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                  {channel.description}
                </p>
                <a
                  href={channel.link}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                >
                  {channel.action}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-3xl font-bold text-center mb-12 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Frequently Asked Questions
          </h2>
          <div className="space-y-8">
            {/* Add FAQ items here if needed */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
