import { useState, useEffect } from 'react'
import { FaCalculator, FaChartLine, FaInfoCircle, FaCheck } from 'react-icons/fa'
import StarRating from '../components/StarRating'

export default function ReviewCalculator() {
  const [currentRating, setCurrentRating] = useState(0)
  const [currentReviews, setCurrentReviews] = useState('')
  const [desiredRating, setDesiredRating] = useState(4.8)
  const [result, setResult] = useState(null)
  const [isCalculated, setIsCalculated] = useState(false)
  const [step, setStep] = useState(1)

  const calculateReviews = () => {
    const current = parseFloat(currentRating)
    const desired = parseFloat(desiredRating)
    const reviews = parseInt(currentReviews)

    if (isNaN(current) || isNaN(desired) || isNaN(reviews)) {
      return
    }

    if (desired <= current) {
      setResult(0)
    } else {
      const x = Math.ceil(
        (desired * (reviews + 1) - current * reviews) / (5 - desired)
      )
      setResult(x)
    }
    
    setIsCalculated(true)
    setStep(2)
  }

  const handleSliderChange = (e, setter) => {
    const value = parseFloat(e.target.value)
    setter(value)
    setIsCalculated(false)
  }

  const stats = [
    {
      percentage: "63.6%",
      text: "of consumers check reviews on Google before visiting a business"
    },
    {
      percentage: "92%",
      text: "of consumers trust recommendations from people they know"
    },
    {
      percentage: "94%",
      text: "of consumers will avoid a business due to its negative reviews"
    }
  ]

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-900 dark:text-white mb-4 font-poppins">
              Star Rating Calculator
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300 font-inter">
              Calculate how many 5-star Google reviews your business needs to improve star rating/rank.
            </p>
          </div>

          {/* Progress Steps */}
          <div className="flex items-center justify-center mb-8">
            <div className="flex items-center">
              <div className={`rounded-full h-8 w-8 flex items-center justify-center font-inter ${
                step >= 1 ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'
              }`}>
                1
              </div>
              <div className={`h-1 w-16 ${
                step === 2 ? 'bg-blue-600' : 'bg-gray-200'
              }`} />
              <div className={`rounded-full h-8 w-8 flex items-center justify-center font-inter ${
                step === 2 ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'
              }`}>
                2
              </div>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 mb-8">
            <div className="space-y-12">
              <div className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-8 font-inter">
                Step {step} of 2
              </div>

              {/* Current Rating Slider */}
              <div>
                <label className="block text-lg font-medium text-gray-700 dark:text-gray-300 mb-2 font-inter">
                  Current Google rating: {currentRating.toFixed(1)}
                </label>
                <div className="flex flex-col space-y-4">
                  <StarRating rating={currentRating} />
                  <input
                    type="range"
                    min="0"
                    max="5"
                    step="0.1"
                    value={currentRating}
                    onChange={(e) => handleSliderChange(e, setCurrentRating)}
                    className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-lg appearance-none cursor-pointer
                             [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-6 
                             [&::-webkit-slider-thumb]:h-6 [&::-webkit-slider-thumb]:bg-blue-600 
                             [&::-webkit-slider-thumb]:dark:bg-blue-500 [&::-webkit-slider-thumb]:rounded-full
                             [&::-webkit-slider-thumb]:cursor-pointer [&::-webkit-slider-thumb]:border-0
                             [&::-webkit-slider-thumb]:transition-all [&::-webkit-slider-thumb]:duration-150
                             [&::-webkit-slider-thumb]:hover:scale-110"
                  />
                  <div className="flex justify-between text-sm text-gray-500 dark:text-gray-400 font-inter">
                    <span>0</span>
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                </div>
              </div>

              {/* Current Reviews Input */}
              <div>
                <label className="block text-lg font-medium text-gray-700 dark:text-gray-300 mb-2 font-inter">
                  Current number of Google reviews
                </label>
                <input
                  type="number"
                  value={currentReviews}
                  onChange={(e) => {
                    setCurrentReviews(e.target.value)
                    setIsCalculated(false)
                  }}
                  min="0"
                  placeholder="0"
                  className="block w-full px-4 py-3 border border-gray-300 dark:border-gray-600 
                           rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                           focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 
                           focus:border-blue-500 dark:focus:border-blue-400 text-2xl font-inter"
                />
              </div>

              {/* Desired Rating Slider */}
              <div>
                <label className="block text-lg font-medium text-gray-700 dark:text-gray-300 mb-2 font-inter">
                  Desired Google rating: {desiredRating.toFixed(1)}
                </label>
                <div className="flex flex-col space-y-4">
                  <StarRating rating={desiredRating} />
                  <input
                    type="range"
                    min="0"
                    max="5"
                    step="0.1"
                    value={desiredRating}
                    onChange={(e) => handleSliderChange(e, setDesiredRating)}
                    className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-lg appearance-none cursor-pointer
                             [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-6 
                             [&::-webkit-slider-thumb]:h-6 [&::-webkit-slider-thumb]:bg-blue-600 
                             [&::-webkit-slider-thumb]:dark:bg-blue-500 [&::-webkit-slider-thumb]:rounded-full
                             [&::-webkit-slider-thumb]:cursor-pointer [&::-webkit-slider-thumb]:border-0
                             [&::-webkit-slider-thumb]:transition-all [&::-webkit-slider-thumb]:duration-150
                             [&::-webkit-slider-thumb]:hover:scale-110"
                  />
                  <div className="flex justify-between text-sm text-gray-500 dark:text-gray-400 font-inter">
                    <span>0</span>
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                </div>
              </div>

              <button
                onClick={calculateReviews}
                className="w-full py-4 px-6 bg-gradient-to-r from-blue-600 to-blue-700 
                         hover:from-blue-700 hover:to-blue-800 text-white rounded-xl 
                         font-semibold flex items-center justify-center space-x-2 
                         transition-all duration-200 transform hover:scale-[1.02]
                         disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100
                         font-inter"
                disabled={!currentRating || !currentReviews || !desiredRating}
              >
                <FaCalculator className="h-5 w-5" />
                <span>Calculate Required Reviews</span>
              </button>
            </div>
          </div>

          {isCalculated && result !== null && (
            <div className="bg-gradient-to-r from-blue-600 to-blue-700 text-white rounded-2xl shadow-xl p-8 text-center animate-fadeIn">
              <div className="bg-white/10 rounded-xl p-6">
                <FaChartLine className="h-12 w-12 mx-auto mb-4" />
                <h2 className="text-2xl font-bold mb-4 font-poppins">Your Results</h2>
                <p className="text-xl mb-6 font-inter">
                  {result === 0 ? (
                    "You've already achieved your desired rating! Keep up the great work!"
                  ) : (
                    `You need ${result} five-star reviews to achieve a ${desiredRating.toFixed(1)} rating`
                  )}
                </p>
              </div>
            </div>
          )}

          {/* Statistics Section */}
          <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
            {stats.map((stat, index) => (
              <div key={index} className="bg-white dark:bg-gray-800 rounded-xl p-6 text-center shadow-lg">
                <p className="text-3xl font-bold text-blue-600 dark:text-blue-400 mb-2 font-poppins">
                  {stat.percentage}
                </p>
                <p className="text-gray-600 dark:text-gray-300 font-inter">
                  {stat.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
