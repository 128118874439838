import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTheme } from './contexts/ThemeContext';
import Navbar from './components/Navbar';
import FloatingCTA from './components/FloatingCTA';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const { theme } = useTheme();
  
  return (
    <div className={`min-h-screen transition-colors duration-200 ${
      theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'
    }`}>
      <Navbar />
      <main>
        <Outlet />
      </main>
      <FloatingCTA />
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
