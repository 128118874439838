export default function StarBackground() {
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      <div className="stars-container">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="star"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 3}s`,
              animationDuration: `${3 + Math.random() * 4}s`
            }}
          >
            ★
          </div>
        ))}
      </div>
    </div>
  )
}
