import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FaStar } from 'react-icons/fa';

const Reviews = () => {
  const { theme } = useTheme();

  const reviews = [
    {
      id: 1,
      name: "Sarah Johnson",
      company: "TechStart Solutions",
      rating: 5,
      text: "5StarHQ has transformed how we handle customer reviews. The AI-powered analysis helps us maintain high standards across all platforms.",
      position: "Marketing Director"
    },
    {
      id: 2,
      name: "Michael Chen",
      company: "Global Retail Inc",
      rating: 5,
      text: "The review management system is intuitive and powerful. It's helped us improve our customer satisfaction significantly.",
      position: "Customer Experience Manager"
    },
    {
      id: 3,
      name: "Emma Williams",
      company: "Service Pro",
      rating: 5,
      text: "Outstanding platform! The AI insights have been invaluable in helping us understand and improve our customer experience.",
      position: "Operations Director"
    }
  ];

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
        <div className="text-center">
          <h1 className={`text-4xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Customer Success Stories
          </h1>
          <p className={`text-xl mb-8 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            See what our customers are saying about 5StarHQ
          </p>
        </div>

        {/* Reviews Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
          {reviews.map((review) => (
            <div
              key={review.id}
              className={`p-6 rounded-lg shadow-lg ${
                theme === 'dark' ? 'bg-gray-800' : 'bg-white'
              }`}
            >
              <div className="flex items-center mb-4">
                {[...Array(review.rating)].map((_, index) => (
                  <FaStar key={index} className="text-yellow-400" />
                ))}
              </div>
              <p className={`mb-4 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                "{review.text}"
              </p>
              <div className="mt-4">
                <p className="font-semibold">{review.name}</p>
                <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                  {review.position}
                </p>
                <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                  {review.company}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Call to Action */}
        <div className="mt-16 text-center">
          <h2 className={`text-2xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Ready to Transform Your Review Management?
          </h2>
          <button
            className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors duration-200"
          >
            Get Started Today
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
