import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { FaClock, FaTag, FaUser, FaCalendar, FaShare, FaTwitter, FaLinkedin, FaFacebook } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export default function BlogPost({ 
  title, 
  content, 
  category, 
  readTime, 
  date, 
  author, 
  tags, 
  image, 
  metaImage, 
  metaDescription,
  canonicalUrl,
  relatedPosts,
  blogPosts
}) {
  const { theme } = useTheme();
  const relatedArticles = relatedPosts?.map(id => blogPosts.find(post => post.id === id)).filter(Boolean) || [];

  const shareUrl = canonicalUrl || window.location.href;
  const shareTitle = encodeURIComponent(title);

  return (
    <>
      <Helmet>
        <title>{title} | 5StarHQ Blog</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={canonicalUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <article className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900' : 'bg-gradient-to-br from-blue-50 via-indigo-50 to-white'}`}>
        {/* Hero Section */}
        <div className="relative h-[60vh]">
          <img 
            src={image} 
            alt={title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center text-white">
              <span className="inline-block bg-blue-600 text-white px-4 py-2 rounded-full text-sm font-semibold mb-4">
                {category}
              </span>
              <h1 className="text-4xl md:text-5xl font-bold mb-4">{title}</h1>
              <div className="flex items-center justify-center space-x-4 text-sm">
                <span className="flex items-center space-x-2">
                  <FaCalendar className="text-gray-300" />
                  <span>{date}</span>
                </span>
                <span className="flex items-center space-x-2">
                  <FaClock className="text-gray-300" />
                  <span>{readTime}</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Author and Share Section */}
          <div className={`flex items-center justify-between mb-8 pb-8 border-b ${theme === 'dark' ? 'border-gray-700' : 'border-gray-200'}`}>
            <div className="flex items-center space-x-4">
              <img 
                src={author.image} 
                alt={author.name}
                className="w-12 h-12 rounded-full"
              />
              <div>
                <h3 className={`font-medium ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {author.name}
                </h3>
                <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
                  {author.role}
                </p>
              </div>
            </div>
            <div className="flex space-x-4">
              <a 
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(window.location.href)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-blue-400"
              >
                <FaTwitter className="h-5 w-5" />
                <span className="sr-only">Share on Twitter</span>
              </a>
              <a 
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(title)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-blue-700"
              >
                <FaLinkedin className="h-5 w-5" />
                <span className="sr-only">Share on LinkedIn</span>
              </a>
              <a 
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-blue-600"
              >
                <FaFacebook className="h-5 w-5" />
                <span className="sr-only">Share on Facebook</span>
              </a>
            </div>
          </div>

          {/* Content */}
          <div className={`prose prose-lg max-w-none ${
            theme === 'dark' 
              ? 'prose-invert prose-headings:text-white prose-p:text-gray-300 prose-a:text-blue-400 prose-strong:text-white prose-code:text-white prose-pre:bg-gray-800 prose-blockquote:text-gray-300 prose-blockquote:border-gray-700 prose-ul:text-gray-300 prose-ol:text-gray-300' 
              : 'prose-gray prose-headings:text-gray-900 prose-a:text-blue-600 prose-pre:bg-gray-50'
          }`}>
            <Markdown
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({node, ...props}) => <h1 className="text-3xl font-bold mt-8 mb-4" {...props} />,
                h2: ({node, ...props}) => <h2 className="text-2xl font-bold mt-6 mb-3" {...props} />,
                h3: ({node, ...props}) => <h3 className="text-xl font-bold mt-4 mb-2" {...props} />,
                a: ({node, ...props}) => <a className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300" {...props} />,
                code: ({node, inline, ...props}) => 
                  inline 
                    ? <code className="bg-gray-100 dark:bg-gray-800 rounded px-1" {...props} />
                    : <code className="block bg-gray-100 dark:bg-gray-800 rounded p-4 my-4" {...props} />,
                img: ({node, ...props}) => <img className="rounded-lg shadow-lg my-8" {...props} />,
                blockquote: ({node, ...props}) => <blockquote className="border-l-4 border-blue-500 pl-4 italic" {...props} />,
              }}
            >
              {content}
            </Markdown>
          </div>

          {/* Blog Post CTA */}
          <div className={`my-16 rounded-2xl ${theme === 'dark' ? 'bg-gray-800' : 'bg-blue-50'} overflow-hidden`}>
            <div className="grid md:grid-cols-5 items-center">
              {/* Content */}
              <div className="p-8 md:p-12 md:col-span-3">
                <div className={`inline-block px-4 py-2 rounded-full text-sm font-semibold mb-4 ${
                  theme === 'dark' ? 'bg-gray-700 text-blue-400' : 'bg-blue-100 text-blue-700'
                }`}>
                  FREE TRIAL
                </div>
                <h3 className={`text-2xl md:text-3xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  Ready to take control of your reviews?
                </h3>
                <p className={`mb-6 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                  Join thousands of businesses using 5StarHQ to manage and improve their online reputation. Start your free trial today and see the difference.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Link 
                    to="/trial-signup"
                    className="inline-flex justify-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 transition-colors"
                  >
                    Start Free Trial
                  </Link>
                  <Link 
                    to="/features"
                    className={`inline-flex justify-center items-center px-6 py-3 border rounded-lg text-base font-medium ${
                      theme === 'dark' 
                        ? 'border-gray-600 text-gray-300 hover:bg-gray-700' 
                        : 'border-blue-200 text-blue-700 hover:bg-blue-100'
                    } transition-colors`}
                  >
                    View Features
                  </Link>
                </div>
              </div>
              {/* Image */}
              <div className="hidden md:block md:col-span-2 h-full">
                <div className="relative h-full min-h-[300px] bg-gradient-to-br from-blue-600 to-indigo-600">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="text-white text-center p-8">
                      <div className="flex justify-center space-x-1 mb-4">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <svg key={star} className="w-8 h-8 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                        ))}
                      </div>
                      <div className="text-4xl font-bold mb-2">4.9/5</div>
                      <div className="text-sm opacity-90">Average Customer Rating</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tags */}
          <div className="mt-8 pt-8 border-t border-gray-200 dark:border-gray-700">
            <div className="flex flex-wrap gap-2">
              {tags.map((tag, index) => (
                <Link 
                  key={index}
                  to={`/blog/tag/${tag.toLowerCase().replace(/\s+/g, '-')}`}
                  className={`inline-flex items-center px-3 py-1 rounded-full text-sm transition-colors duration-200 ${
                    theme === 'dark'
                      ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  <FaTag className="mr-2 text-xs" />
                  {tag}
                </Link>
              ))}
            </div>
          </div>

          {/* Related Posts */}
          {relatedArticles.length > 0 && (
            <div className="mt-16">
              <h2 className={`text-2xl font-bold mb-8 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                Related Articles
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {relatedArticles.map((post) => (
                  <Link 
                    key={post.id} 
                    to={`/blog/${post.id}`}
                    className={`block group ${
                      theme === 'dark' 
                        ? 'bg-gray-800 hover:bg-gray-700' 
                        : 'bg-white hover:bg-gray-50'
                    } rounded-lg shadow-sm overflow-hidden transition-all duration-200`}
                  >
                    <div className="aspect-w-16 aspect-h-9">
                      <img 
                        src={post.image} 
                        alt={post.title}
                        className="w-full h-48 object-cover"
                      />
                    </div>
                    <div className="p-6">
                      <h3 className={`text-xl font-semibold mb-2 group-hover:text-blue-600 ${
                        theme === 'dark' ? 'text-white' : 'text-gray-900'
                      }`}>
                        {post.title}
                      </h3>
                      <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
                        {post.metaDescription}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </article>
    </>
  );
}
