import { FaStar, FaStarHalfAlt } from 'react-icons/fa'

export default function StarRating({ rating }) {
  const stars = []
  const fullStars = Math.floor(rating)
  const hasHalfStar = rating % 1 !== 0

  // Add full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(<FaStar key={`star-${i}`} className="text-yellow-400 h-6 w-6" />)
  }

  // Add half star if needed
  if (hasHalfStar) {
    stars.push(<FaStarHalfAlt key="half-star" className="text-yellow-400 h-6 w-6" />)
  }

  // Add empty stars
  const emptyStars = 5 - Math.ceil(rating)
  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <FaStar 
        key={`empty-star-${i}`} 
        className="text-gray-300 dark:text-gray-600 h-6 w-6" 
      />
    )
  }

  return <div className="flex">{stars}</div>
}
