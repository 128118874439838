import React, { useEffect, useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import StarBackground from './StarBackground';

export default function Hero() {
  const { theme } = useTheme();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const fadeInClass = isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4';
  
  return (
    <div className={`relative overflow-hidden ${theme === 'dark' ? 'bg-gray-900' : 'bg-gradient-to-br from-blue-50 via-indigo-50 to-white'} py-24`}>
      {theme === 'dark' && <StarBackground />}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="text-center max-w-4xl mx-auto">
          <h1 className={`text-5xl md:text-6xl font-bold ${theme === 'dark' ? 'text-white' : 'text-gray-900'} mb-8 leading-tight transition-all duration-700 ${fadeInClass}`}>
            Transform Your Business With<br />
            <span className="bg-gradient-to-r from-blue-600 to-indigo-600 text-transparent bg-clip-text">
              5-Star Reviews
            </span>
          </h1>
          <p className={`text-xl ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'} mb-10 transition-all duration-700 delay-200 ${fadeInClass}`}>
            Harness the power of AI to automate your review management and skyrocket your online reputation.
          </p>
          <div className={`space-y-4 transition-all duration-700 delay-400 ${fadeInClass}`}>
            <Link 
              to="/trial-signup"
              className={`inline-block px-10 py-5 rounded-full text-lg font-semibold text-white bg-gradient-to-r from-blue-600 via-indigo-600 to-blue-700 hover:from-blue-700 hover:via-indigo-700 hover:to-blue-800 transform hover:scale-105 transition-all duration-200 shadow-xl ${theme === 'dark' ? 'hover:shadow-indigo-500/40' : 'hover:shadow-indigo-500/60'} animate-pulse hover:animate-none`}
            >
              Ready to Transform Your Online Reputation?
            </Link>
            <p className={`text-lg ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
              Join thousands of businesses already using 5StarHQ to dominate their reviews
            </p>
          </div>
          
          <div className={`mt-16 grid grid-cols-1 md:grid-cols-3 gap-8 transition-all duration-700 delay-600 ${fadeInClass}`}>
            {[
              { number: '10x', text: 'Faster Response Time' },
              { number: '89%', text: 'Increase in Positive Reviews' },
              { number: '24/7', text: 'AI-Powered Monitoring' }
            ].map((stat, index) => (
              <div key={index} className={`${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                <div 
                  className="text-4xl font-bold opacity-0"
                  style={{
                    animation: `fadeInUp 0.6s ease-out forwards ${index * 0.2}s`
                  }}
                >
                  {stat.number}
                </div>
                <div 
                  className={`mt-2 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'} opacity-0`}
                  style={{
                    animation: `fadeInUp 0.6s ease-out forwards ${index * 0.2 + 0.1}s`
                  }}
                >
                  {stat.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
