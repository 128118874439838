import React from 'react';
import { FaStar, FaArrowRight } from 'react-icons/fa';

const transformations = [
  {
    id: 1,
    business: "Luxury Hotel Resort",
    before: {
      rating: 3.2,
      reviews: 156,
      responseRate: "15%",
      avgResponseTime: "5 days",
      image: "https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
    },
    after: {
      rating: 4.8,
      reviews: 450,
      responseRate: "98%",
      avgResponseTime: "2 hours",
      image: "https://images.unsplash.com/photo-1542314831-068cd1dbfeeb?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
    }
  },
  {
    id: 2,
    business: "Fine Dining Restaurant",
    before: {
      rating: 3.8,
      reviews: 89,
      responseRate: "25%",
      avgResponseTime: "3 days",
      image: "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
    },
    after: {
      rating: 4.7,
      reviews: 320,
      responseRate: "95%",
      avgResponseTime: "4 hours",
      image: "https://images.unsplash.com/photo-1559339352-11d035aa65de?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80"
    }
  }
];

export default function BeforeAfter() {
  const renderStars = (rating) => {
    return (
      <div className="flex items-center gap-1">
        {[...Array(5)].map((_, index) => (
          <FaStar
            key={index}
            className={index < Math.floor(rating) ? 'text-yellow-400' : 'text-gray-300'}
          />
        ))}
        <span className="ml-2 text-gray-600 dark:text-gray-300">
          {rating.toFixed(1)}
        </span>
      </div>
    );
  };

  return (
    <section className="py-24 bg-gray-50 dark:bg-gray-800 transition-colors duration-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-extrabold mb-6 tracking-tight leading-tight text-gray-900 dark:text-white">
            Real Results, Real Impact
          </h2>
          <p className="text-xl md:text-2xl font-light max-w-3xl mx-auto text-gray-600 dark:text-gray-300">
            See how businesses transformed their online reputation with 5StarHQ
          </p>
        </div>

        {/* Transformations */}
        <div className="space-y-20">
          {transformations.map((item) => (
            <div key={item.id} className="relative">
              {/* Business Name */}
              <h3 className="text-2xl font-bold mb-8 text-center text-gray-900 dark:text-white">
                {item.business}
              </h3>

              {/* Before/After Grid */}
              <div className="grid md:grid-cols-2 gap-8 relative">
                {/* Connector Line */}
                <div className="hidden md:block absolute top-1/2 left-1/2 w-12 h-12 -translate-x-1/2 -translate-y-1/2 bg-blue-500 rounded-full z-10">
                  <FaArrowRight className="text-white text-xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                </div>

                {/* Before Card */}
                <div className="rounded-2xl overflow-hidden transition-all duration-300 bg-white dark:bg-gray-700 shadow-xl">
                  <div className="relative h-64 overflow-hidden">
                    <img
                      src={item.before.image}
                      alt={`${item.business} before`}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute top-4 left-4">
                      <span className="bg-red-500 text-white px-4 py-2 rounded-full text-sm font-semibold">
                        Before
                      </span>
                    </div>
                  </div>
                  <div className="p-8">
                    <div className="mb-6">{renderStars(item.before.rating)}</div>
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Total Reviews
                        </p>
                        <p className="text-2xl font-bold text-gray-900 dark:text-white">
                          {item.before.reviews}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Response Rate
                        </p>
                        <p className="text-2xl font-bold text-gray-900 dark:text-white">
                          {item.before.responseRate}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Avg Response Time
                        </p>
                        <p className="text-2xl font-bold text-gray-900 dark:text-white">
                          {item.before.avgResponseTime}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* After Card */}
                <div className="rounded-2xl overflow-hidden transition-all duration-300 bg-white dark:bg-gray-700 shadow-xl">
                  <div className="relative h-64 overflow-hidden">
                    <img
                      src={item.after.image}
                      alt={`${item.business} after`}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute top-4 left-4">
                      <span className="bg-green-500 text-white px-4 py-2 rounded-full text-sm font-semibold">
                        After
                      </span>
                    </div>
                  </div>
                  <div className="p-8">
                    <div className="mb-6">{renderStars(item.after.rating)}</div>
                    <div className="grid grid-cols-2 gap-6">
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Total Reviews
                        </p>
                        <p className="text-2xl font-bold text-gray-900 dark:text-white">
                          {item.after.reviews}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Response Rate
                        </p>
                        <p className="text-2xl font-bold text-gray-900 dark:text-white">
                          {item.after.responseRate}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Avg Response Time
                        </p>
                        <p className="text-2xl font-bold text-gray-900 dark:text-white">
                          {item.after.avgResponseTime}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Call to Action */}
        <div className="text-center mt-20">
          <p className="text-xl font-light mb-8 text-gray-600 dark:text-gray-300">
            Ready to transform your business's online reputation?
          </p>
          <button className="inline-flex items-center space-x-3 px-10 py-5 rounded-xl text-xl font-semibold transition-all duration-200 hover:shadow-xl hover:scale-105 text-white bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700">
            <span>Start Your Transformation</span>
            <FaArrowRight className="ml-2" />
          </button>
        </div>
      </div>
    </section>
  );
}
