import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { FaShieldAlt, FaUserLock, FaServer, FaGlobe, FaEnvelope, FaLock, FaHandshake, FaQuestion } from 'react-icons/fa';

const PrivacyPolicy = () => {
  const { theme } = useTheme();

  const sections = [
    {
      icon: <FaShieldAlt className="w-8 h-8" />,
      title: "Introduction",
      content: `This Privacy Policy explains how 5StarHQ ("we," "us," or "our") collects, uses, and protects your personal information. By using our services, you agree to the collection and use of information in accordance with this policy.

Key Points:
• We value your privacy and transparency
• We only collect information necessary for service provision
• We protect your data using industry-standard security measures
• You have control over your personal information`
    },
    {
      icon: <FaUserLock className="w-8 h-8" />,
      title: "Information We Collect",
      content: `We collect information that you provide directly to us, including:

Personal Information:
• Name and contact information
• Account credentials
• Business information
• Payment information
• Communication preferences

Technical Information:
• Usage data and analytics
• Device information
• Browser type and version
• IP address and location data
• Cookies and similar technologies

We collect this information when you:
• Create an account
• Use our services
• Contact our support
• Subscribe to newsletters
• Participate in surveys`
    },
    {
      icon: <FaServer className="w-8 h-8" />,
      title: "How We Use Your Information",
      content: `We use the collected information for:

Service Provision:
• Providing and maintaining our services
• Processing transactions
• Customer support
• Service optimization

Communication:
• Sending administrative information
• Marketing communications (with consent)
• Service updates and announcements
• Responding to your requests

Analysis and Improvement:
• Improving user experience
• Analytics and performance monitoring
• Security and fraud prevention
• Product development

We process your data based on:
• Contract performance
• Legal obligations
• Legitimate interests
• Your consent`
    },
    {
      icon: <FaLock className="w-8 h-8" />,
      title: "Data Protection",
      content: `We implement comprehensive security measures to protect your data:

Technical Measures:
• Encryption in transit and at rest
• Secure data storage
• Regular security audits
• Access controls

Organizational Measures:
• Employee training
• Data access policies
• Incident response plans
• Regular security reviews

Data Retention:
• We keep data only as long as necessary
• Regular data review and cleanup
• Secure data disposal procedures`
    },
    {
      icon: <FaHandshake className="w-8 h-8" />,
      title: "Your Rights",
      content: `Under applicable data protection laws, you have the following rights:

Access and Control:
• Access your personal data
• Correct inaccurate data
• Request deletion of your data
• Object to data processing
• Data portability
• Withdraw consent

Additional Rights:
• Restrict processing
• Lodge complaints
• Opt-out of marketing
• Access data processing records

How to Exercise Your Rights:
• Contact our DPO
• Use privacy dashboard
• Submit formal requests
• Contact supervisory authorities`
    },
    {
      icon: <FaGlobe className="w-8 h-8" />,
      title: "Third-Party Services",
      content: `We may use third-party services that collect, monitor, and analyze user data:

Service Providers:
• Analytics providers
• Payment processors
• Hosting services
• Marketing platforms

Data Sharing:
• Only with your consent
• For service provision
• Legal requirements
• Business transfers

Third-Party Policies:
• Independent privacy policies
• Separate data controllers
• Own terms of service`
    },
    {
      icon: <FaEnvelope className="w-8 h-8" />,
      title: "Updates to Privacy Policy",
      content: `We may update this Privacy Policy periodically:

Update Process:
• Regular policy reviews
• Notification of changes
• Grace period for major changes
• Version history maintained

Change Management:
• Email notifications
• Website announcements
• In-app notifications
• Consent renewal if required`
    },
    {
      icon: <FaQuestion className="w-8 h-8" />,
      title: "Contact Us",
      content: `For privacy-related inquiries:

Data Protection Officer:
• Email: privacy@5starhq.com
• Phone: +1 (555) 123-4567
• Address: 123 Privacy Street, San Francisco, CA 94105

Response Time:
• Within 24 hours for urgent matters
• Within 5 business days for general inquiries
• Within 30 days for formal requests`
    }
  ];

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="relative py-16 bg-gradient-to-b from-blue-600 to-blue-800 dark:from-blue-800 dark:to-blue-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl text-white mb-6">
              Privacy Policy
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base sm:text-lg md:mt-5 md:text-xl md:max-w-3xl text-white/90 mb-12">
              Protecting Your Privacy and Data Rights
            </p>
            <div className="flex justify-center space-x-4 mb-8">
              <Link
                to="/gdpr"
                className="px-6 py-3 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-colors duration-200"
              >
                GDPR Rights
              </Link>
              <Link
                to="/contact"
                className="px-6 py-3 bg-blue-700 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
              >
                Contact DPO
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Quick Links */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className={`rounded-lg shadow-xl p-8 ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'}`}>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {sections.map((section, index) => (
              <a
                key={index}
                href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`}
                className={`p-4 rounded-lg ${
                  theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-50'
                } transition-colors duration-200 flex flex-col items-center text-center`}
              >
                <div className={`mb-2 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {section.icon}
                </div>
                <span className="text-sm font-medium">{section.title}</span>
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {sections.map((section, index) => (
            <section
              key={index}
              id={section.title.toLowerCase().replace(/\s+/g, '-')}
              className={`p-6 rounded-lg ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} shadow-lg`}
            >
              <div className="flex items-center mb-6">
                <div className={`mr-4 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {section.icon}
                </div>
                <h2 className={`text-2xl font-bold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {section.title}
                </h2>
              </div>
              <div className={`prose max-w-none ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'} whitespace-pre-line`}>
                {section.content}
              </div>
            </section>
          ))}
        </div>

        {/* Contact Section */}
        <div className={`mt-16 p-8 rounded-lg text-center ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
          <h2 className={`text-2xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Have Questions About Your Privacy?
          </h2>
          <p className={`text-lg mb-8 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            Our Data Protection Officer is here to help with any privacy-related concerns.
          </p>
          <div className="flex justify-center space-x-4">
            <Link
              to="/contact"
              className="px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
            >
              Contact DPO
            </Link>
            <Link
              to="/gdpr"
              className={`px-8 py-3 rounded-lg transition-colors duration-200 ${
                theme === 'dark'
                  ? 'bg-gray-700 text-white hover:bg-gray-600'
                  : 'bg-white text-gray-900 hover:bg-gray-100'
              }`}
            >
              View GDPR Rights
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
