import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { 
  FaBars, 
  FaTimes, 
  FaCalculator, 
  FaFeather, 
  FaTag, 
  FaSearch,
  FaNewspaper,
  FaSignInAlt,
  FaRocket
} from 'react-icons/fa';
import Logo from './Logo';
import ThemeToggle from './ThemeToggle';

export default function Navbar() {
  const { theme } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navLinks = [
    { to: '/features', text: 'Features', icon: <FaFeather className="inline-block mr-1" /> },
    { to: '/pricing', text: 'Pricing', icon: <FaTag className="inline-block mr-1" /> },
    { to: '/review-calculator', text: 'Review Calculator', icon: <FaCalculator className="inline-block mr-1" /> },
    { to: '/review-checker', text: 'Review Checker', icon: <FaSearch className="inline-block mr-1" /> },
    { to: '/blog', text: 'Blog', icon: <FaNewspaper className="inline-block mr-1" /> },
  ];

  return (
    <nav className={`${theme === 'dark' ? 'bg-gray-900 border-gray-800' : 'bg-white'} border-b transition-colors duration-200 sticky top-0 z-50`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              <Logo className="h-8 w-8" />
              <span className={`text-xl md:text-2xl font-bold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                5StarHQ
              </span>
            </Link>
          </div>
          
          {/* Mobile menu button */}
          <div className="flex items-center lg:hidden">
            <ThemeToggle />
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="ml-2 p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none"
            >
              {isMenuOpen ? (
                <FaTimes className="h-6 w-6" />
              ) : (
                <FaBars className="h-6 w-6" />
              )}
            </button>
          </div>

          {/* Desktop menu */}
          <div className="hidden lg:flex lg:items-center lg:space-x-6">
            {navLinks.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className={`${
                  theme === 'dark' ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'
                } transition-colors flex items-center hover:scale-105 duration-200`}
              >
                {link.icon} {link.text}
              </Link>
            ))}
            <ThemeToggle />
            <Link
              to="/login"
              className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors flex items-center hover:scale-105 duration-200"
            >
              <FaSignInAlt className="inline-block mr-1" /> Log In
            </Link>
            <Link
              to="/trial-signup"
              className="px-4 py-2 rounded-full text-white bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 transform hover:scale-105 transition-all duration-200 flex items-center"
            >
              <FaRocket className="inline-block mr-1" /> Start Free Trial
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`lg:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          {navLinks.map((link) => (
            <Link
              key={link.to}
              to={link.to}
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                theme === 'dark'
                  ? 'text-gray-300 hover:text-white hover:bg-gray-800'
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
              } transition-colors flex items-center`}
              onClick={() => setIsMenuOpen(false)}
            >
              {link.icon} {link.text}
            </Link>
          ))}
          <Link
            to="/login"
            className="block px-3 py-2 text-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors flex items-center"
            onClick={() => setIsMenuOpen(false)}
          >
            <FaSignInAlt className="inline-block mr-1" /> Log In
          </Link>
          <Link
            to="/trial-signup"
            className="block px-3 py-2 mt-4 text-center rounded-full text-white bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 transition-all duration-200 flex items-center justify-center"
            onClick={() => setIsMenuOpen(false)}
          >
            <FaRocket className="inline-block mr-1" /> Start Free Trial
          </Link>
        </div>
      </div>
    </nav>
  );
}
