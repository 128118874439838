import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function FloatingCTA() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 500)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className={`fixed bottom-8 right-8 z-50 transition-transform duration-300 ${
      isVisible ? 'translate-y-0' : 'translate-y-[200%]'
    }`}>
      <Link 
        to="/trial-signup"
        className="inline-block bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
      >
        Start Free Trial
      </Link>
    </div>
  )
}
