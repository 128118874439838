import { Link } from 'react-router-dom';

export default function CallToAction() {
  return (
    <div className="bg-blue-600 text-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold mb-8">Take control of your Google reviews</h2>
        <Link 
          to="/trial-signup"
          className="inline-block bg-white text-blue-600 px-8 py-4 rounded-lg font-semibold hover:bg-gray-100 transition-colors"
        >
          START YOUR FREE TRIAL
        </Link>
      </div>
    </div>
  )
}
