import React from 'react';
import { Link } from 'react-router-dom';
import BlogCard from './BlogCard';
import { useTheme } from '../contexts/ThemeContext';
import blogPosts from '../data/blogPosts';

export default function BlogSection() {
  const { theme } = useTheme();

  return (
    <div className={`py-20 ${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-50'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className={`text-3xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Latest Insights
          </h2>
          <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Expert advice and strategies for managing your online reputation and handling Google reviews effectively.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {blogPosts.slice(0, 3).map((blog) => (
            <BlogCard
              key={blog.id}
              id={blog.id}
              title={blog.title}
              description={blog.description}
              image={blog.image || `https://placehold.co/800x400/2563eb/ffffff/png?text=${encodeURIComponent(blog.title)}`}
              category={blog.category || 'Article'}
              date={blog.date}
              readTime={blog.readTime || '5 min read'}
            />
          ))}
        </div>

        {/* Blog CTA Section */}
        <div className={`rounded-2xl p-8 md:p-12 ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} shadow-xl`}>
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h3 className={`text-2xl md:text-3xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                Want to learn more about managing your reviews?
              </h3>
              <p className={`mb-6 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                Subscribe to our newsletter and get expert tips, industry insights, and actionable strategies delivered straight to your inbox.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <Link 
                  to="/trial-signup"
                  className="inline-flex justify-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 transition-colors"
                >
                  Start Free Trial
                </Link>
                <Link 
                  to="/blog"
                  className={`inline-flex justify-center items-center px-6 py-3 border rounded-lg text-base font-medium ${
                    theme === 'dark' 
                      ? 'border-gray-600 text-gray-300 hover:bg-gray-700' 
                      : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                  } transition-colors`}
                >
                  View All Articles
                </Link>
              </div>
            </div>
            <div className="hidden md:block">
              <img 
                src="/images/newsletter-illustration.svg" 
                alt="Newsletter illustration" 
                className="w-full max-w-md mx-auto"
                onError={(e) => {
                  e.target.style.display = 'none';
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
