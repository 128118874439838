import React from 'react';
import { Link } from 'react-router-dom';
import { FaStar, FaRobot, FaChartLine, FaComments, FaBullhorn, FaChartBar, FaArrowRight } from 'react-icons/fa';

const features = [
  {
    icon: <FaStar className="text-4xl text-yellow-400" />,
    title: "Review Generation",
    description: "Automatically collect authentic reviews from happy customers with our smart review generation system."
  },
  {
    icon: <FaRobot className="text-4xl text-blue-500" />,
    title: "AI Response Assistant",
    description: "Let AI craft perfect responses to reviews, maintaining your brand voice while saving hours of time."
  },
  {
    icon: <FaChartLine className="text-4xl text-green-500" />,
    title: "Reputation Monitoring",
    description: "Track and analyze your online reputation across all major platforms in one unified dashboard."
  },
  {
    icon: <FaComments className="text-4xl text-purple-500" />,
    title: "Review Management",
    description: "Manage all your reviews from Google, Yelp, and other platforms in one centralized location."
  },
  {
    icon: <FaBullhorn className="text-4xl text-red-500" />,
    title: "Marketing Tools",
    description: "Turn your best reviews into powerful marketing assets with our automated social media tools."
  },
  {
    icon: <FaChartBar className="text-4xl text-indigo-500" />,
    title: "Competitor Analysis",
    description: "Stay ahead of the competition with detailed insights and comparative analytics."
  }
];

export default function Features() {
  return (
    <section className="py-20 bg-gray-50 dark:bg-gray-900 transition-colors duration-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4 text-gray-900 dark:text-white">
            Everything You Need to Dominate Reviews
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
            Powerful tools to manage and improve your online reputation
          </p>
          <Link
            to="/trial-signup"
            className="inline-flex items-center px-8 py-4 text-lg font-semibold text-gray-900 bg-white rounded-full shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 transition-all duration-200 border-2 border-gray-900 hover:bg-gray-50"
          >
            Start Your Free Trial
            <FaArrowRight className="ml-2" />
          </Link>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-xl 
                       transition-all duration-200 transform hover:-translate-y-1 
                       hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              <div className="flex justify-center mb-4">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold mb-3 text-gray-900 dark:text-white">
                {feature.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* Bottom CTA */}
        <div className="text-center mt-12 py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl shadow-xl">
          <h3 className="text-3xl font-bold text-white mb-6">
            Ready to Transform Your Review Management?
          </h3>
          <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
            Join thousands of businesses using 5StarHQ to boost their online reputation
          </p>
          <Link
            to="/trial-signup"
            className="inline-flex items-center px-8 py-4 text-lg font-semibold text-gray-900 bg-white rounded-full shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 transition-all duration-200 border-2 border-transparent hover:bg-gray-50"
          >
            Start Your Free Trial
            <FaArrowRight className="ml-2" />
          </Link>
        </div>
      </div>
    </section>
  );
}
