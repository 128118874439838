import BlogSection from '../components/BlogSection'

export default function Blog() {
  return (
    <div className="pt-20">
      <div className="bg-gradient-to-r from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold mb-6">Review Management Resources</h1>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
              Expert insights, strategies, and tips for managing your online reputation and handling Google reviews effectively.
            </p>
          </div>
        </div>
      </div>
      <BlogSection />
    </div>
  )
}
