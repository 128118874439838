import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { FaShieldAlt, FaUserLock, FaFileAlt, FaGlobe, FaBan, FaExclamationTriangle, FaUserShield, FaDatabase } from 'react-icons/fa';

const GDPR = () => {
  const { theme } = useTheme();

  const rights = [
    {
      icon: <FaUserLock className="w-8 h-8" />,
      title: "Right to Access",
      content: "Request a copy of your personal data and information about how we process it.",
      action: "Request Data Access"
    },
    {
      icon: <FaFileAlt className="w-8 h-8" />,
      title: "Right to Rectification",
      content: "Request corrections to your personal data if it is inaccurate or incomplete.",
      action: "Update Data"
    },
    {
      icon: <FaBan className="w-8 h-8" />,
      title: "Right to Erasure",
      content: "Request deletion of your personal data (right to be forgotten).",
      action: "Request Deletion"
    },
    {
      icon: <FaExclamationTriangle className="w-8 h-8" />,
      title: "Right to Object",
      content: "Object to processing of your personal data for specific purposes.",
      action: "Submit Objection"
    },
    {
      icon: <FaGlobe className="w-8 h-8" />,
      title: "Right to Data Portability",
      content: "Receive your data in a structured, commonly used format.",
      action: "Export Data"
    },
    {
      icon: <FaUserShield className="w-8 h-8" />,
      title: "Right to Restrict Processing",
      content: "Limit how we use your personal data in certain circumstances.",
      action: "Restrict Processing"
    },
    {
      icon: <FaDatabase className="w-8 h-8" />,
      title: "Right to Information",
      content: "Be informed about how we process and protect your data.",
      action: "View Details"
    },
    {
      icon: <FaShieldAlt className="w-8 h-8" />,
      title: "Right to Withdraw Consent",
      content: "Withdraw your consent for processing at any time.",
      action: "Manage Consent"
    }
  ];

  const sections = [
    {
      title: "GDPR Compliance Overview",
      content: `5StarHQ is committed to protecting the privacy rights of individuals in the European Union (EU) and European Economic Area (EEA). We comply with the General Data Protection Regulation (GDPR) by:
        • Processing personal data lawfully and transparently
        • Collecting data only for specific, legitimate purposes
        • Minimizing data collection to what's necessary
        • Keeping data accurate and up-to-date
        • Storing data securely and for limited time periods
        • Ensuring data integrity and confidentiality
        • Implementing appropriate technical measures
        • Conducting regular privacy impact assessments`
    },
    {
      title: "Legal Basis for Processing",
      content: `We process personal data under the following legal bases:
        • Consent: When you explicitly agree to the processing
        • Contract: When processing is necessary for a contract
        • Legal Obligation: When required by law
        • Legitimate Interests: When we have a justified business purpose
        • Vital Interests: To protect someone's life
        • Public Interest: When required for official functions

      We maintain detailed records of our processing activities and their legal bases.`
    },
    {
      title: "International Data Transfers",
      content: `When transferring data outside the EU/EEA, we ensure appropriate safeguards through:
        • Standard Contractual Clauses (SCCs)
        • Adequacy decisions by the European Commission
        • Binding Corporate Rules
        • Privacy Shield certification (where applicable)
        
      We regularly review and update our data transfer mechanisms to ensure compliance with current regulations.`
    },
    {
      title: "Data Protection Measures",
      content: `We implement comprehensive data protection measures:
        • Encryption of personal data at rest and in transit
        • Regular security assessments and penetration testing
        • Access controls and authentication mechanisms
        • Employee training on data protection
        • Incident response procedures
        • Regular backup and recovery testing
        • Monitoring and logging of data access
        • Vendor security assessments`
    },
    {
      title: "Data Protection Officer",
      content: `Our Data Protection Officer (DPO) oversees GDPR compliance and can be contacted at:
        • Email: dpo@5starhq.com
        • Address: 123 Review Street, San Francisco, CA 94105
        • Phone: +1 (555) 123-4567
        
      The DPO's responsibilities include:
        • Monitoring GDPR compliance
        • Providing advice on data protection
        • Cooperating with supervisory authorities
        • Handling data subject requests
        • Maintaining processing records`
    },
    {
      title: "Data Breach Notification",
      content: `In case of a personal data breach, we will:
        • Notify supervisory authorities within 72 hours
        • Inform affected individuals without undue delay
        • Document all breaches and our response
        • Take measures to mitigate any adverse effects
        • Conduct post-incident analysis
        • Update security measures as needed`
    },
    {
      title: "Privacy by Design",
      content: `We implement privacy by design principles through:
        • Data minimization
        • Purpose limitation
        • Storage limitation
        • Security measures
        • Regular audits and assessments
        • Privacy impact assessments
        • Employee training
        • Documentation of processes`
    }
  ];

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="relative py-16 bg-gradient-to-b from-blue-600 to-blue-800 dark:from-blue-800 dark:to-blue-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
            GDPR Compliance
          </h1>
          <p className="text-xl mb-12 text-white/90">
            Your Privacy Rights Under the General Data Protection Regulation
          </p>
          <div className="flex justify-center space-x-4 mb-8">
            <Link
              to="/contact"
              className="px-6 py-3 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-colors duration-200"
            >
              Contact DPO
            </Link>
            <Link
              to="/privacy-policy"
              className="px-6 py-3 bg-blue-700 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>

      {/* Your Rights Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className={`rounded-lg shadow-xl p-8 ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'}`}>
          <h2 className={`text-3xl font-bold text-center mb-12 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Your Rights Under GDPR
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {rights.map((right, index) => (
              <div
                key={index}
                className={`p-6 rounded-lg ${
                  theme === 'dark' ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-50 hover:bg-gray-100'
                } flex flex-col items-center text-center transition-colors duration-200 cursor-pointer`}
                onClick={() => {
                  // Handle right action
                  console.log(`${right.action} clicked`);
                }}
              >
                <div className={`mb-4 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {right.icon}
                </div>
                <h3 className={`text-xl font-semibold mb-2 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {right.title}
                </h3>
                <p className={`mb-4 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                  {right.content}
                </p>
                <span className={`text-sm font-medium ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {right.action} →
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {sections.map((section, index) => (
            <div
              key={index}
              className={`p-6 rounded-lg ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} shadow-lg`}
            >
              <h2 className={`text-2xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                {section.title}
              </h2>
              <div className={`prose max-w-none ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                <p className="whitespace-pre-line">{section.content}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Contact Section */}
        <div className={`mt-16 p-8 rounded-lg text-center ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
          <h2 className={`text-2xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Exercise Your GDPR Rights
          </h2>
          <p className={`text-lg mb-8 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            Have questions about your privacy rights or need to submit a request?
          </p>
          <div className="flex justify-center space-x-4">
            <Link
              to="/contact"
              className="px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
            >
              Contact DPO
            </Link>
            <Link
              to="/privacy-policy"
              className={`px-8 py-3 rounded-lg transition-colors duration-200 ${
                theme === 'dark'
                  ? 'bg-gray-700 text-white hover:bg-gray-600'
                  : 'bg-white text-gray-900 hover:bg-gray-100'
              }`}
            >
              View Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GDPR;
