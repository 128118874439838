import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';
import Logo from './Logo';

export default function Footer() {
  const { theme } = useTheme();
  
  const footerLinks = {
    Product: [
      { name: 'Features', href: '/features' },
      { name: 'Pricing', href: '/pricing' },
      { name: 'Reviews', href: '/reviews' },
      { name: 'Case Studies', href: '/case-studies' },
    ],
    Company: [
      { name: 'About Us', href: '/about' },
      { name: 'Blog', href: '/blog' },
      { name: 'Contact', href: '/contact' },
      { name: 'Affiliates', href: '/affiliates' },
    ],
    Resources: [
      { name: 'Help Center', href: '/help' },
      { name: 'Documentation', href: '/docs' },
      { name: 'Community', href: '/community' },
      { name: 'Support', href: '/support' },
    ],
    Legal: [
      { name: 'Privacy Policy', href: '/privacy-policy' },
      { name: 'Terms of Service', href: '/terms-of-service' },
      { name: 'Cookie Policy', href: '/cookie-policy' },
      { name: 'GDPR', href: '/gdpr' },
    ],
  };

  return (
    <footer 
      className={`${
        theme === 'dark' 
          ? 'bg-gray-900 text-gray-300 border-t border-gray-800' 
          : 'bg-white text-gray-600 border-t border-gray-200'
      } py-12`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-8 mb-12">
          {/* Brand Column */}
          <div className="col-span-2 md:col-span-4 lg:col-span-1">
            <Link to="/" className="flex items-center space-x-2 mb-4">
              <Logo className="h-8 w-8" />
              <span className={`text-2xl font-bold ${
                theme === 'dark' ? 'text-white' : 'text-gray-900'
              }`}>
                5StarHQ
              </span>
            </Link>
            <p className={`mb-4 text-sm ${
              theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
            }`}>
              Transform your business with AI-powered review management.
            </p>
            <div className="flex space-x-6">
              <a 
                href="https://www.linkedin.com/company/fivestarhq" 
                target="_blank" 
                rel="noopener noreferrer" 
                className={`hover:text-blue-500 transition-colors ${
                  theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                }`}
              >
                <FaLinkedin className="text-xl" />
                <span className="sr-only">LinkedIn</span>
              </a>
              <a 
                href="https://www.facebook.com/5starhq.com.au" 
                target="_blank" 
                rel="noopener noreferrer" 
                className={`hover:text-blue-600 transition-colors ${
                  theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                }`}
              >
                <FaFacebook className="text-xl" />
                <span className="sr-only">Facebook</span>
              </a>
              <a 
                href="https://www.instagram.com/5starhq.com.au" 
                target="_blank" 
                rel="noopener noreferrer" 
                className={`hover:text-pink-500 transition-colors ${
                  theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                }`}
              >
                <FaInstagram className="text-xl" />
                <span className="sr-only">Instagram</span>
              </a>
              <a 
                href="https://www.youtube.com/channel/UCQjUTjsmpPlNX-0591ZAENQ" 
                target="_blank" 
                rel="noopener noreferrer" 
                className={`hover:text-red-600 transition-colors ${
                  theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
                }`}
              >
                <FaYoutube className="text-xl" />
                <span className="sr-only">YouTube</span>
              </a>
            </div>
          </div>

          {/* Links Columns */}
          {Object.entries(footerLinks).map(([category, links]) => (
            <div key={category} className="col-span-1">
              <h3 className={`text-sm font-semibold mb-3 ${
                theme === 'dark' ? 'text-white' : 'text-gray-900'
              }`}>
                {category}
              </h3>
              <ul className="space-y-2">
                {links.map((link) => (
                  <li key={link.name}>
                    <Link 
                      to={link.href}
                      className={`text-sm transition-colors ${
                        theme === 'dark'
                          ? 'text-gray-400 hover:text-white'
                          : 'text-gray-500 hover:text-gray-900'
                      }`}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className={`pt-6 mt-6 border-t ${
          theme === 'dark' ? 'border-gray-800' : 'border-gray-200'
        }`}>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className={`text-xs ${
              theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
            }`}>
              &copy; {new Date().getFullYear()} 5StarHQ. All rights reserved.
            </p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <Link 
                to="/terms-of-service" 
                className={`text-xs transition-colors ${
                  theme === 'dark'
                    ? 'text-gray-400 hover:text-white'
                    : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                Terms
              </Link>
              <Link 
                to="/privacy-policy" 
                className={`text-xs transition-colors ${
                  theme === 'dark'
                    ? 'text-gray-400 hover:text-white'
                    : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                Privacy
              </Link>
              <Link 
                to="/cookie-policy" 
                className={`text-xs transition-colors ${
                  theme === 'dark'
                    ? 'text-gray-400 hover:text-white'
                    : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                Cookies
              </Link>
              <Link 
                to="/sitemap.xml" 
                className={`text-xs transition-colors ${
                  theme === 'dark'
                    ? 'text-gray-400 hover:text-white'
                    : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                Sitemap
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
