import { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaCheck, FaStar, FaArrowRight, FaCrown, FaRocket } from 'react-icons/fa'
import { useTheme } from '../contexts/ThemeContext'

export default function Pricing() {
  const [isAnnual, setIsAnnual] = useState(true)
  const { theme } = useTheme()
  
  const plans = [
    {
      name: 'Basic',
      icon: <FaStar className="text-yellow-400 text-4xl mb-4" />,
      price: isAnnual ? 29 : 39,
      description: "Perfect for small businesses just getting started with review management.",
      features: [
        'Up to 100 reviews/month',
        'Basic sentiment analysis',
        'Email support',
        'Review monitoring',
        'Basic reporting'
      ],
      color: 'blue'
    },
    {
      name: 'Pro',
      icon: <FaRocket className="text-purple-500 text-4xl mb-4" />,
      price: isAnnual ? 79 : 89,
      description: "Ideal for growing businesses that need advanced features and analytics.",
      popular: true,
      features: [
        'Up to 500 reviews/month',
        'Advanced sentiment analysis',
        'Priority email & chat support',
        'Review monitoring & alerts',
        'Advanced reporting & analytics',
        'Competitor tracking',
        'Review response templates'
      ],
      color: 'purple'
    },
    {
      name: 'Enterprise',
      icon: <FaCrown className="text-amber-500 text-4xl mb-4" />,
      price: isAnnual ? 199 : 219,
      description: "For large organizations requiring complete review management solutions.",
      features: [
        'Unlimited reviews',
        'AI-powered sentiment analysis',
        '24/7 dedicated support',
        'Real-time monitoring & alerts',
        'Custom reporting & analytics',
        'Multi-location management',
        'White-label solutions',
        'API access',
        'Custom integrations'
      ],
      color: 'amber'
    }
  ]

  return (
    <div className={`min-h-screen py-20 ${theme === 'dark' ? 'bg-gray-900' : 'bg-gradient-to-b from-gray-50 to-white'}`}>
      {/* Pricing Header */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className={`text-5xl font-extrabold mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Simple, Transparent Pricing
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-2xl mx-auto">
            Choose the perfect plan for your business. Save 20% with annual billing.
          </p>
          
          {/* Billing Toggle */}
          <div className="flex items-center justify-center mb-8 bg-white dark:bg-gray-800 rounded-full p-1 shadow-lg max-w-xs mx-auto">
            <button
              onClick={() => setIsAnnual(false)}
              className={`px-6 py-2 rounded-full text-sm font-semibold transition-all duration-200 ${
                !isAnnual 
                  ? 'bg-gray-900 text-white shadow-sm' 
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              Monthly
            </button>
            <button
              onClick={() => setIsAnnual(true)}
              className={`px-6 py-2 rounded-full text-sm font-semibold transition-all duration-200 ${
                isAnnual 
                  ? 'bg-gray-900 text-white shadow-sm' 
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              Annual
            </button>
          </div>
        </div>

        {/* Pricing Cards */}
        <div className="max-w-7xl mx-auto grid grid-cols-1 gap-8 lg:grid-cols-3 mb-16">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`relative rounded-2xl ${
                theme === 'dark' ? 'bg-gray-800' : 'bg-white'
              } shadow-xl transform transition-all duration-200 hover:-translate-y-1 hover:shadow-2xl overflow-hidden`}
            >
              {plan.popular && (
                <div className="absolute top-0 right-0 mt-4 mr-4">
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-semibold bg-gradient-to-r from-purple-600 to-pink-600 text-white">
                    Most Popular
                  </span>
                </div>
              )}
              <div className="p-8">
                <div className="text-center">
                  {plan.icon}
                  <h3 className={`text-2xl font-bold mb-2 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                    {plan.name}
                  </h3>
                  <p className="text-gray-500 dark:text-gray-400 mb-6">
                    {plan.description}
                  </p>
                  <div className="flex justify-center items-baseline mb-8">
                    <span className={`text-6xl font-extrabold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                      ${plan.price}
                    </span>
                    <span className="text-gray-500 dark:text-gray-400 ml-2">/mo</span>
                  </div>
                </div>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-center text-gray-600 dark:text-gray-300">
                      <FaCheck className={`text-${plan.color}-500 mr-3 flex-shrink-0`} />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <Link
                  to="/trial-signup"
                  className={`w-full inline-flex items-center justify-center px-6 py-4 rounded-xl text-lg font-semibold transition-all duration-200 ${
                    plan.popular
                      ? 'bg-gradient-to-r from-purple-600 to-pink-600 text-white hover:from-purple-700 hover:to-pink-700'
                      : 'bg-white text-gray-900 border-2 border-gray-900 hover:bg-gray-50'
                  }`}
                >
                  Start Free Trial
                  <FaArrowRight className="ml-2" />
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/* Bottom CTA */}
        <div className={`text-center max-w-4xl mx-auto rounded-2xl p-8 ${
          theme === 'dark' ? 'bg-gray-800' : 'bg-blue-50'
        }`}>
          <h3 className={`text-2xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Need a Custom Enterprise Solution?
          </h3>
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            Let's create a tailored plan that perfectly fits your organization's needs.
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center px-6 py-3 rounded-xl text-lg font-semibold bg-white text-gray-900 border-2 border-gray-900 hover:bg-gray-50 transition-all duration-200"
          >
            Contact Sales
            <FaArrowRight className="ml-2" />
          </Link>
        </div>
      </div>
    </div>
  )
}
