import React, { useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FaSearch, FaBook, FaVideo, FaQuestionCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Help = () => {
  const { theme } = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [openFaq, setOpenFaq] = useState(null);

  const categories = [
    {
      icon: <FaBook className="text-3xl" />,
      title: "Documentation",
      description: "Detailed guides and API references"
    },
    {
      icon: <FaVideo className="text-3xl" />,
      title: "Video Tutorials",
      description: "Step-by-step visual guides"
    },
    {
      icon: <FaQuestionCircle className="text-3xl" />,
      title: "FAQs",
      description: "Common questions answered"
    }
  ];

  const faqs = [
    {
      question: "How does 5StarHQ's AI review management work?",
      answer: "Our AI-powered system analyzes customer reviews across multiple platforms, providing insights and automated responses while maintaining a personal touch. It uses natural language processing to understand sentiment and key themes in reviews."
    },
    {
      question: "What platforms does 5StarHQ integrate with?",
      answer: "5StarHQ integrates with major review platforms including Google Business Profile, Yelp, TripAdvisor, Facebook, and many more. We're constantly adding new integrations based on user needs."
    },
    {
      question: "How can I get started with 5StarHQ?",
      answer: "Getting started is easy! Simply sign up for a free trial, connect your review platforms, and our system will begin analyzing your reviews immediately. Our onboarding team will guide you through the process."
    },
    {
      question: "What kind of support do you offer?",
      answer: "We offer 24/7 email support, live chat during business hours, and priority phone support for enterprise customers. Our help center is always available with detailed guides and tutorials."
    }
  ];

  const handleFaqClick = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="relative py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className={`text-4xl md:text-5xl font-bold mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            How can we help?
          </h1>
          
          {/* Search Bar */}
          <div className="max-w-2xl mx-auto">
            <div className="relative">
              <FaSearch className={`absolute left-4 top-1/2 transform -translate-y-1/2 ${
                theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
              }`} />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search for help..."
                className={`w-full pl-12 pr-4 py-4 rounded-lg ${
                  theme === 'dark' 
                    ? 'bg-gray-800 text-white placeholder-gray-400' 
                    : 'bg-white text-gray-900 placeholder-gray-500'
                } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Help Categories */}
      <div className={`py-16 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {categories.map((category, index) => (
              <div
                key={index}
                className={`p-8 rounded-lg ${
                  theme === 'dark' ? 'bg-gray-700' : 'bg-white'
                } flex flex-col items-center text-center cursor-pointer hover:transform hover:scale-105 transition-transform duration-200`}
              >
                <div className={`mb-4 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {category.icon}
                </div>
                <h3 className={`text-xl font-semibold mb-2 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {category.title}
                </h3>
                <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                  {category.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="py-16">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-3xl font-bold text-center mb-12 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Frequently Asked Questions
          </h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className={`rounded-lg ${
                  theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'
                }`}
              >
                <button
                  onClick={() => handleFaqClick(index)}
                  className="w-full px-6 py-4 flex justify-between items-center"
                >
                  <span className={`text-left font-medium ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                    {faq.question}
                  </span>
                  {openFaq === index ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {openFaq === index && (
                  <div className={`px-6 pb-4 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Contact Support */}
      <div className={`py-16 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className={`text-3xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Still Need Help?
          </h2>
          <p className={`text-xl mb-8 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            Our support team is available 24/7 to assist you
          </p>
          <button className="px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200">
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
};

export default Help;
