import React from 'react';
import { useRouteError, Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { FaHome, FaArrowLeft } from 'react-icons/fa';

const ErrorPage = () => {
  const error = useRouteError();
  const { theme } = useTheme();
  const navigate = useNavigate();

  const funnyMessages = [
    "Oops! Looks like our AI took an unexpected coffee break! ☕",
    "404: Page got lost in the cloud... literally! ☁️",
    "Houston, we have a problem... but our team is on it! 🚀",
    "This page is playing hide and seek... and winning! 🙈",
    "Seems like our hamsters needed a quick nap! 🐹",
  ];

  const randomMessage = funnyMessages[Math.floor(Math.random() * funnyMessages.length)];

  return (
    <div className={`min-h-screen flex items-center justify-center px-4 ${
      theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'
    }`}>
      <div className="text-center">
        <div className="text-6xl mb-8">
          {error.status === 404 ? '😅' : '🤔'}
        </div>
        
        <h1 className="text-4xl font-bold mb-4">
          {randomMessage}
        </h1>
        
        <p className={`text-lg mb-6 ${
          theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
        }`}>
          {error.status === 404 
            ? "The page you're looking for has gone on vacation without leaving a forwarding address."
            : "Something went wrong, but our best engineers are already on the case!"}
        </p>

        <div className="space-y-4">
          <p className={`text-sm ${
            theme === 'dark' ? 'text-gray-400' : 'text-gray-500'
          }`}>
            Error: {error.statusText || error.message}
          </p>

          <div className="flex justify-center space-x-4">
            <button
              onClick={() => navigate(-1)}
              className={`flex items-center px-6 py-3 rounded-lg ${
                theme === 'dark' 
                  ? 'bg-gray-800 hover:bg-gray-700' 
                  : 'bg-white hover:bg-gray-100'
              } transition-colors duration-200`}
            >
              <FaArrowLeft className="mr-2" />
              Go Back
            </button>
            
            <Link
              to="/"
              className="flex items-center px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200"
            >
              <FaHome className="mr-2" />
              Home Page
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
