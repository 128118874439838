import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { FaStar, FaChartLine } from 'react-icons/fa';

export default function Restaurant() {
  const { theme } = useTheme();
  
  return (
    <div className={`min-h-screen py-20 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="mb-16">
          <h1 className={`text-5xl font-bold mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Restaurant Group Success Story
          </h1>
          <p className={`text-xl ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            How we automated review monitoring across 12 locations and saved 20+ hours per week
          </p>
        </div>

        {/* Main Image */}
        <div className="rounded-2xl overflow-hidden mb-16 h-[500px]">
          <img
            src="https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-4.0.3&auto=format&fit=crop&w=1920&q=80"
            alt="Restaurant Interior"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Key Metrics */}
        <div className={`grid md:grid-cols-3 gap-8 mb-16 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
          <div className={`p-8 rounded-xl ${theme === 'dark' ? 'bg-gray-700' : 'bg-white'} shadow-lg`}>
            <div className="text-4xl font-bold text-blue-500 mb-2">20hrs</div>
            <div className="text-lg">Time Saved Weekly</div>
          </div>
          <div className={`p-8 rounded-xl ${theme === 'dark' ? 'bg-gray-700' : 'bg-white'} shadow-lg`}>
            <div className="text-4xl font-bold text-blue-500 mb-2">-85%</div>
            <div className="text-lg">Response Time</div>
          </div>
          <div className={`p-8 rounded-xl ${theme === 'dark' ? 'bg-gray-700' : 'bg-white'} shadow-lg`}>
            <div className="text-4xl font-bold text-blue-500 mb-2">+60%</div>
            <div className="text-lg">New Reviews/Month</div>
          </div>
        </div>

        {/* Detailed Content */}
        <div className={`prose max-w-none ${theme === 'dark' ? 'prose-invert' : ''}`}>
          <h2>The Challenge</h2>
          <p>Managing reviews across 12 restaurant locations was becoming increasingly complex and time-consuming. The management team was spending countless hours manually monitoring and responding to reviews, taking away from other critical operations.</p>

          <h2>Our Solution</h2>
          <p>We implemented a comprehensive review management solution that:</p>
          <ul>
            <li>Centralized review monitoring for all 12 locations</li>
            <li>Automated response generation with location-specific details</li>
            <li>Provided real-time alerts for negative reviews</li>
            <li>Generated detailed analytics for each location</li>
          </ul>

          <h2>The Results</h2>
          <p>The restaurant group experienced significant improvements across all metrics:</p>
          <ul>
            <li>Saved 20+ hours per week on review management</li>
            <li>Reduced response time by 85%</li>
            <li>Increased monthly review volume by 60%</li>
            <li>Improved overall rating from 3.8 to 4.6</li>
          </ul>
        </div>

        {/* Testimonial */}
        <div className={`my-16 p-8 rounded-xl ${theme === 'dark' ? 'bg-gray-700' : 'bg-white'} shadow-lg`}>
          <div className="flex text-yellow-400 text-xl gap-1 mb-4">
            {[...Array(5)].map((_, i) => (
              <FaStar key={i} />
            ))}
          </div>
          <blockquote className={`text-xl italic mb-4 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            "The AI-powered responses have been a game-changer for our multi-location business."
          </blockquote>
          <div>
            <p className={`font-bold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>David Chen</p>
            <p className={theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}>Operations Director</p>
          </div>
        </div>
      </div>
    </div>
  );
}
