import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { FaCookie, FaShieldAlt, FaChartBar, FaCogs, FaUserCog } from 'react-icons/fa';

const Cookies = () => {
  const { theme } = useTheme();

  const sections = [
    {
      title: "What Are Cookies?",
      icon: <FaCookie className="w-8 h-8" />,
      content: `Cookies are small text files that are placed on your device when you visit our website. They help us provide you with a better experience by:
        • Remembering your preferences
        • Understanding how you use our site
        • Providing personalized content
        • Improving our services`
    },
    {
      title: "Types of Cookies We Use",
      icon: <FaShieldAlt className="w-8 h-8" />,
      content: `1. Essential Cookies:
        • Required for basic site functionality
        • Cannot be disabled
        • Used for authentication and security

      2. Functional Cookies:
        • Remember your preferences
        • Enhance site usability
        • Store language preferences
        • Remember login details

      3. Analytics Cookies:
        • Help us understand site usage
        • Track visitor behavior
        • Improve our services
        • Measure performance

      4. Marketing Cookies:
        • Used for targeted advertising
        • Track across different websites
        • Measure ad effectiveness
        • Personalize content`
    },
    {
      title: "Managing Your Cookies",
      icon: <FaUserCog className="w-8 h-8" />,
      content: `You can control cookies through your browser settings:
        • Chrome: Settings > Privacy and Security > Cookies
        • Firefox: Options > Privacy & Security > Cookies
        • Safari: Preferences > Privacy > Cookies
        • Edge: Settings > Privacy & Security > Cookies

      Note: Disabling certain cookies may affect site functionality.`
    },
    {
      title: "Analytics & Performance",
      icon: <FaChartBar className="w-8 h-8" />,
      content: `We use third-party services that may set cookies:
        • Google Analytics for site usage analysis
        • Social media plugins for sharing
        • Payment processors for secure transactions
        • Marketing tools for personalization

      Each third-party service has its own cookie policy and data practices.`
    },
    {
      title: "Technical Details",
      icon: <FaCogs className="w-8 h-8" />,
      content: `Our cookie implementation:
        • Follows GDPR compliance
        • Respects user privacy
        • Uses secure storage methods
        • Regular security audits
        
      We regularly review and update our cookie practices to maintain security and privacy standards.`
    }
  ];

  const cookieCategories = [
    {
      name: "Essential",
      description: "Required for basic functionality",
      required: true,
      enabled: true
    },
    {
      name: "Functional",
      description: "Enhance your experience",
      required: false,
      enabled: true
    },
    {
      name: "Analytics",
      description: "Help us improve our services",
      required: false,
      enabled: true
    },
    {
      name: "Marketing",
      description: "Personalized content and ads",
      required: false,
      enabled: false
    }
  ];

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="relative py-16 bg-gradient-to-b from-blue-600 to-blue-800 dark:from-blue-800 dark:to-blue-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
            Cookie Settings
          </h1>
          <p className="text-xl mb-12 text-white/90">
            Control how we use cookies to improve your experience
          </p>
          <div className="flex justify-center space-x-4 mb-8">
            <Link
              to="/privacy-policy"
              className="px-6 py-3 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-colors duration-200"
            >
              Privacy Policy
            </Link>
            <Link
              to="/gdpr"
              className="px-6 py-3 bg-blue-700 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
            >
              GDPR Rights
            </Link>
          </div>
        </div>
      </div>

      {/* Cookie Preferences */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className={`rounded-lg shadow-xl p-6 mb-12 ${
          theme === 'dark' ? 'bg-gray-800' : 'bg-white'
        }`}>
          <h2 className={`text-2xl font-bold mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Cookie Preferences
          </h2>
          <div className="space-y-4">
            {cookieCategories.map((category, index) => (
              <div key={index} className={`p-4 rounded-lg ${
                theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50'
              }`}>
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className={`font-semibold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                      {category.name} Cookies
                    </h3>
                    <p className={`text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                      {category.description}
                    </p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={category.enabled}
                      disabled={category.required}
                      onChange={() => {
                        // Implement cookie preference change
                        console.log(`Toggle ${category.name} cookies`);
                      }}
                    />
                    <div className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 
                      peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer 
                      dark:bg-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white 
                      after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white 
                      after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 
                      after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}></div>
                  </label>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-8 flex justify-end space-x-4">
            <button
              className={`px-6 py-2 rounded-lg ${
                theme === 'dark'
                  ? 'bg-gray-700 hover:bg-gray-600 text-white'
                  : 'bg-gray-200 hover:bg-gray-300 text-gray-900'
              }`}
              onClick={() => {
                // Reset preferences
                console.log('Reset preferences');
              }}
            >
              Reset All
            </button>
            <button
              className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              onClick={() => {
                // Save preferences
                console.log('Save preferences');
              }}
            >
              Save Preferences
            </button>
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {sections.map((section, index) => (
            <div key={index} className={`p-6 rounded-lg ${
              theme === 'dark' ? 'bg-gray-800' : 'bg-white'
            } shadow-lg`}>
              <div className="flex items-center mb-4">
                <div className={`mr-4 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {section.icon}
                </div>
                <h2 className={`text-xl font-bold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {section.title}
                </h2>
              </div>
              <div className={`prose max-w-none ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                <p className="whitespace-pre-line">{section.content}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Additional Links */}
        <div className="mt-12 text-center">
          <p className={`mb-4 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            For more information about how we protect your privacy:
          </p>
          <div className="space-x-4">
            <Link
              to="/privacy-policy"
              className="text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
            >
              Privacy Policy
            </Link>
            <span className={theme === 'dark' ? 'text-gray-600' : 'text-gray-400'}>•</span>
            <Link
              to="/terms-of-service"
              className="text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
