import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { FaStar, FaChartLine } from 'react-icons/fa';

export default function LuxuryHotel() {
  const { theme } = useTheme();
  
  return (
    <div className={`min-h-screen py-20 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="mb-16">
          <h1 className={`text-5xl font-bold mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Luxury Hotel Chain Success Story
          </h1>
          <p className={`text-xl ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            How we helped transform their review response strategy and boost their online reputation
          </p>
        </div>

        {/* Main Image */}
        <div className="rounded-2xl overflow-hidden mb-16 h-[500px]">
          <img
            src="https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&auto=format&fit=crop&w=1920&q=80"
            alt="Luxury Hotel"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Key Metrics */}
        <div className={`grid md:grid-cols-3 gap-8 mb-16 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
          <div className={`p-8 rounded-xl ${theme === 'dark' ? 'bg-gray-700' : 'bg-white'} shadow-lg`}>
            <div className="text-4xl font-bold text-blue-500 mb-2">+1.8</div>
            <div className="text-lg">Review Score Increase</div>
          </div>
          <div className={`p-8 rounded-xl ${theme === 'dark' ? 'bg-gray-700' : 'bg-white'} shadow-lg`}>
            <div className="text-4xl font-bold text-blue-500 mb-2">98%</div>
            <div className="text-lg">Response Rate</div>
          </div>
          <div className={`p-8 rounded-xl ${theme === 'dark' ? 'bg-gray-700' : 'bg-white'} shadow-lg`}>
            <div className="text-4xl font-bold text-blue-500 mb-2">+40%</div>
            <div className="text-lg">Customer Satisfaction</div>
          </div>
        </div>

        {/* Detailed Content */}
        <div className={`prose max-w-none ${theme === 'dark' ? 'prose-invert' : ''}`}>
          <h2>The Challenge</h2>
          <p>Our luxury hotel chain client was struggling to maintain consistent review responses across their properties. With multiple locations and a high volume of daily reviews, their team was overwhelmed and response times were increasing.</p>

          <h2>Our Solution</h2>
          <p>We implemented our AI-powered review management system that:</p>
          <ul>
            <li>Automated review monitoring across all major platforms</li>
            <li>Generated personalized, on-brand responses</li>
            <li>Provided real-time analytics and insights</li>
            <li>Streamlined the approval process</li>
          </ul>

          <h2>The Results</h2>
          <p>Within 6 months of implementation, the hotel chain saw dramatic improvements:</p>
          <ul>
            <li>Review score increased from 4.2 to 4.8</li>
            <li>98% response rate to all reviews</li>
            <li>40% increase in customer satisfaction</li>
            <li>Reduced response time from 72 hours to 4 hours</li>
          </ul>
        </div>

        {/* Testimonial */}
        <div className={`my-16 p-8 rounded-xl ${theme === 'dark' ? 'bg-gray-700' : 'bg-white'} shadow-lg`}>
          <div className="flex text-yellow-400 text-xl gap-1 mb-4">
            {[...Array(5)].map((_, i) => (
              <FaStar key={i} />
            ))}
          </div>
          <blockquote className={`text-xl italic mb-4 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            "5StarHQ helped us maintain our luxury reputation across all review platforms."
          </blockquote>
          <div>
            <p className={`font-bold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>Emma Thompson</p>
            <p className={theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}>Guest Relations Manager</p>
          </div>
        </div>
      </div>
    </div>
  );
}
