import React from 'react';
import { FaStar, FaRobot, FaChartLine, FaComments, FaBullhorn, FaChartBar, 
         FaUserShield, FaChartPie, FaGlobe, FaTools, FaMobileAlt, FaHeadset } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';

const allFeatures = [
  {
    category: "Core Features",
    items: [
      {
        icon: <FaStar className="text-4xl text-yellow-400" />,
        title: "Review Generation",
        description: "Automatically collect authentic reviews from happy customers with our smart review generation system. Send perfectly timed review requests and make it easy for customers to share their experiences."
      },
      {
        icon: <FaRobot className="text-4xl text-blue-500" />,
        title: "AI Response Assistant",
        description: "Let AI craft perfect responses to reviews, maintaining your brand voice while saving hours of time. Our AI understands context and sentiment to create personalized, meaningful responses."
      },
      {
        icon: <FaChartLine className="text-4xl text-green-500" />,
        title: "Reputation Monitoring",
        description: "Track and analyze your online reputation across all major platforms in one unified dashboard. Get real-time alerts and detailed analytics about your review performance."
      }
    ]
  },
  {
    category: "Management Tools",
    items: [
      {
        icon: <FaComments className="text-4xl text-purple-500" />,
        title: "Review Management",
        description: "Manage all your reviews from Google, Yelp, and other platforms in one centralized location. Never miss a review and maintain consistent response times."
      },
      {
        icon: <FaBullhorn className="text-4xl text-red-500" />,
        title: "Marketing Tools",
        description: "Turn your best reviews into powerful marketing assets with our automated social media tools. Create engaging content from positive reviews and share them across your channels."
      },
      {
        icon: <FaChartBar className="text-4xl text-indigo-500" />,
        title: "Competitor Analysis",
        description: "Stay ahead of the competition with detailed insights and comparative analytics. Monitor competitor ratings, review volumes, and response strategies."
      }
    ]
  },
  {
    category: "Advanced Features",
    items: [
      {
        icon: <FaUserShield className="text-4xl text-emerald-500" />,
        title: "Review Verification",
        description: "Ensure the authenticity of reviews with our advanced verification system. Detect and flag suspicious review patterns and protect your reputation."
      },
      {
        icon: <FaChartPie className="text-4xl text-orange-500" />,
        title: "Sentiment Analysis",
        description: "Understand customer sentiment with our AI-powered analysis tools. Get detailed insights into what customers love and what needs improvement."
      },
      {
        icon: <FaGlobe className="text-4xl text-cyan-500" />,
        title: "Multi-Location Support",
        description: "Manage reviews for multiple business locations from one dashboard. Compare performance across locations and maintain consistent quality."
      }
    ]
  },
  {
    category: "Additional Tools",
    items: [
      {
        icon: <FaTools className="text-4xl text-gray-500" />,
        title: "Integration & APIs",
        description: "Connect 5StarHQ with your existing tools and workflows. Our robust API allows for seamless integration with your CRM and other business systems."
      },
      {
        icon: <FaMobileAlt className="text-4xl text-pink-500" />,
        title: "Mobile App",
        description: "Manage your reviews on the go with our powerful mobile app. Get instant notifications and respond to reviews from anywhere, anytime."
      },
      {
        icon: <FaHeadset className="text-4xl text-teal-500" />,
        title: "24/7 Support",
        description: "Get expert support whenever you need it. Our dedicated team is available 24/7 to help you make the most of your review management strategy."
      }
    ]
  }
];

export default function FeaturesPage() {
  const { theme } = useTheme();

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900' : 'bg-gray-50'} transition-colors duration-200`}>
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-600 dark:from-blue-800 dark:to-indigo-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className={`text-4xl md:text-5xl font-extrabold ${theme === 'dark' ? 'text-white' : 'text-gray-900'} mb-6`}>
              Powerful Features to Transform Your Online Reputation
            </h1>
            <p className={`text-xl md:text-2xl ${theme === 'dark' ? 'text-blue-100' : 'text-gray-600'} max-w-3xl mx-auto`}>
              Everything you need to collect, manage, and leverage customer reviews to grow your business
            </p>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {allFeatures.map((category, categoryIndex) => (
          <div key={categoryIndex} className="mb-20">
            <h2 className={`text-3xl font-bold ${theme === 'dark' ? 'text-white' : 'text-gray-900'} mb-12 text-center`}>
              {category.category}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {category.items.map((feature, index) => (
                <div
                  key={index}
                  className={`bg-${theme === 'dark' ? 'gray-800' : 'white'} rounded-xl shadow-lg hover:shadow-xl 
                           transition-all duration-200 transform hover:-translate-y-1 overflow-hidden`}
                >
                  <div className="p-8">
                    <div className="flex justify-center mb-6">
                      {feature.icon}
                    </div>
                    <h3 className={`text-xl font-semibold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'} text-center`}>
                      {feature.title}
                    </h3>
                    <p className={`text-${theme === 'dark' ? 'gray-300' : 'gray-600'} text-center`}>
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-600 dark:from-blue-800 dark:to-indigo-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="text-center">
            <h2 className={`text-3xl font-bold text-white mb-6`}>
              Ready to Transform Your Online Reputation?
            </h2>
            <p className={`text-xl ${theme === 'dark' ? 'text-blue-100' : 'text-gray-600'} mb-8`}>
              Join thousands of businesses already using 5StarHQ to dominate their reviews
            </p>
            <button className={`bg-${theme === 'dark' ? 'gray-800' : 'white'} text-blue-600 px-8 py-4 rounded-lg font-semibold text-lg
                           hover:bg-blue-50 transition-colors duration-200 transform hover:scale-105`}>
              Start Your Free Trial
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
