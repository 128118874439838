import React from 'react';
import { useTheme } from '../contexts/ThemeContext';

const CookiePolicy = () => {
  const { theme } = useTheme();

  const sections = [
    {
      title: "What Are Cookies",
      content: `Cookies are small text files that are placed on your device when you visit our website. They help us provide you with a better experience by:

• Remembering your preferences
• Understanding how you use our website
• Improving site functionality
• Personalizing content and advertisements`
    },
    {
      title: "Types of Cookies We Use",
      content: `We use the following types of cookies:

1. Essential Cookies
• Required for the website to function
• Cannot be disabled
• Enable basic features like page navigation and access to secure areas

2. Performance Cookies
• Help us understand how visitors interact with our website
• Collect anonymous statistical information
• Used to improve website performance

3. Functionality Cookies
• Remember your preferences
• Enable personalized features
• Store your choices for future visits

4. Targeting/Advertising Cookies
• Track your browsing habits
• Deliver targeted advertising
• Measure the effectiveness of our advertising campaigns`
    },
    {
      title: "Third-Party Cookies",
      content: `We may use cookies from trusted third-party services:

• Google Analytics for website analytics
• Social media platforms for sharing and engagement
• Advertising partners for targeted advertisements
• Payment processors for secure transactions

These third parties may also use cookies, over which we have no control. Please refer to their respective privacy policies for more information.`
    },
    {
      title: "Cookie Management",
      content: `You can control and manage cookies in various ways:

• Browser Settings: Configure your browser to accept, reject, or notify you when cookies are set
• Cookie Consent: Use our cookie consent tool to manage your preferences
• Third-Party Tools: Install browser plugins for additional control

Note that blocking some types of cookies may impact your experience on our website and the services we offer.`
    },
    {
      title: "Specific Cookie Purposes",
      content: `We use cookies for the following purposes:

• Authentication and security
• Session management
• Traffic analysis
• User experience improvement
• Personalized content delivery
• Marketing and advertising optimization
• Performance monitoring`
    },
    {
      title: "Data Collection and Use",
      content: `Information collected through cookies may include:

• IP address and browser information
• Pages visited and time spent
• Referral sources
• Click patterns and preferences
• Device information

This information is used in accordance with our Privacy Policy.`
    },
    {
      title: "Updates to Cookie Policy",
      content: `We may update this Cookie Policy from time to time to reflect changes in our practices or for operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated policy on our website.`
    },
    {
      title: "Contact Us",
      content: `If you have questions about our Cookie Policy or how we use cookies, please contact us at privacy@5starhq.com`
    }
  ];

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="relative py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className={`text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl ${
              theme === 'dark' ? 'text-white' : 'text-gray-900'
            }`}>
              Cookie Policy
            </h1>
            <p className={`mt-3 max-w-md mx-auto text-base sm:text-lg md:mt-5 md:text-xl md:max-w-3xl ${
              theme === 'dark' ? 'text-gray-300' : 'text-gray-500'
            }`}>
              Last updated: {new Date().toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-16">
        <div className="space-y-16">
          {sections.map((section, index) => (
            <section key={index} className="prose max-w-none">
              <h2 className={`text-2xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                {section.title}
              </h2>
              <div className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'} whitespace-pre-line`}>
                {section.content}
              </div>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
