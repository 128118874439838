import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FaBook, FaCode, FaVideo, FaLightbulb } from 'react-icons/fa';

const Documentation = () => {
  const { theme } = useTheme();

  const docCategories = [
    {
      icon: <FaBook className="text-3xl" />,
      title: "Getting Started",
      description: "Learn the basics of 5StarHQ and get up and running quickly.",
      links: [
        { text: "Quick Start Guide", url: "/docs/quickstart" },
        { text: "Installation", url: "/docs/installation" },
        { text: "Basic Concepts", url: "/docs/concepts" }
      ]
    },
    {
      icon: <FaCode className="text-3xl" />,
      title: "API Reference",
      description: "Detailed documentation of our API endpoints and integration guides.",
      links: [
        { text: "API Overview", url: "/docs/api" },
        { text: "Authentication", url: "/docs/auth" },
        { text: "Endpoints", url: "/docs/endpoints" }
      ]
    },
    {
      icon: <FaVideo className="text-3xl" />,
      title: "Tutorials",
      description: "Step-by-step guides and video tutorials for common use cases.",
      links: [
        { text: "Video Tutorials", url: "/docs/tutorials" },
        { text: "Best Practices", url: "/docs/best-practices" },
        { text: "Examples", url: "/docs/examples" }
      ]
    },
    {
      icon: <FaLightbulb className="text-3xl" />,
      title: "Resources",
      description: "Additional resources, guides, and reference materials.",
      links: [
        { text: "User Guide", url: "/docs/user-guide" },
        { text: "FAQs", url: "/docs/faqs" },
        { text: "Troubleshooting", url: "/docs/troubleshooting" }
      ]
    }
  ];

  const popularArticles = [
    {
      title: "Getting Started with 5StarHQ",
      description: "Learn how to set up and configure your 5StarHQ environment.",
      url: "/docs/getting-started"
    },
    {
      title: "Authentication Best Practices",
      description: "Secure your application with our authentication guidelines.",
      url: "/docs/auth-best-practices"
    },
    {
      title: "API Integration Guide",
      description: "Step-by-step guide to integrating our API into your application.",
      url: "/docs/api-integration"
    }
  ];

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="relative py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className={`text-4xl md:text-5xl font-bold mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Documentation
          </h1>
          <p className={`text-xl mb-8 max-w-3xl mx-auto ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            Everything you need to know about using and integrating with 5StarHQ.
          </p>
          <div className="max-w-2xl mx-auto">
            <div className={`relative rounded-lg ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'}`}>
              <input
                type="text"
                placeholder="Search documentation..."
                className={`w-full px-4 py-3 rounded-lg ${
                  theme === 'dark' ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
                } border ${theme === 'dark' ? 'border-gray-700' : 'border-gray-300'}`}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Documentation Categories */}
      <div className={`py-16 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {docCategories.map((category, index) => (
              <div
                key={index}
                className={`p-6 rounded-lg ${
                  theme === 'dark' ? 'bg-gray-700' : 'bg-white'
                }`}
              >
                <div className={`mb-4 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {category.icon}
                </div>
                <h3 className={`text-xl font-semibold mb-2 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {category.title}
                </h3>
                <p className={`mb-4 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                  {category.description}
                </p>
                <ul className="space-y-2">
                  {category.links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                      <a
                        href={link.url}
                        className="text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                      >
                        {link.text} →
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Popular Articles */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-3xl font-bold text-center mb-12 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Popular Articles
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {popularArticles.map((article, index) => (
              <a
                key={index}
                href={article.url}
                className={`p-6 rounded-lg transition-transform duration-200 transform hover:scale-105 ${
                  theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'
                }`}
              >
                <h3 className={`text-xl font-semibold mb-2 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {article.title}
                </h3>
                <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                  {article.description}
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
