import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import {
  FaHandshake,
  FaKey,
  FaUserCircle,
  FaCogs,
  FaCreditCard,
  FaDatabase,
  FaBan,
  FaExclamationTriangle,
  FaBalanceScale,
  FaGavel,
  FaEnvelope
} from 'react-icons/fa';

const TermsOfService = () => {
  const { theme } = useTheme();

  const sections = [
    {
      icon: <FaHandshake className="w-8 h-8" />,
      title: "Agreement to Terms",
      content: `By accessing or using 5StarHQ's services, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing our services.

Key Points:
• These terms constitute a legally binding agreement
• Your use of our services indicates acceptance
• You must be of legal age to accept these terms
• You are responsible for compliance with local laws`
    },
    {
      icon: <FaKey className="w-8 h-8" />,
      title: "Use License",
      content: `We grant you a limited, non-exclusive, non-transferable, revocable license to use our services for your personal or business use, subject to these Terms of Service.

License Restrictions:
• No modification or copying of our software
• No illegal or unauthorized use
• No decompilation or reverse engineering
• No account transfers without consent
• Must comply with all applicable laws

License Termination:
• We may terminate your license for violations
• You may terminate by discontinuing use
• Some terms survive termination`
    },
    {
      icon: <FaUserCircle className="w-8 h-8" />,
      title: "Account Terms",
      content: `To access certain features of our service, you must register for an account.

Account Requirements:
• Accurate and complete information
• Valid email address
• Strong password creation
• Regular security updates

Account Responsibilities:
• Maintain account security
• Keep information current
• Report unauthorized access
• No sharing or transfer

Account Restrictions:
• One account per user
• Must be of legal age
• No impersonation
• No automated account creation`
    },
    {
      icon: <FaCogs className="w-8 h-8" />,
      title: "Service Modifications",
      content: `We reserve the right to modify our services to improve user experience and maintain quality.

Modification Rights:
• Change or discontinue features
• Update pricing structures
• Modify access permissions
• Revise terms and policies

Notification Process:
• Email for major changes
• In-app notifications
• Website announcements
• 30-day notice for material changes`
    },
    {
      icon: <FaCreditCard className="w-8 h-8" />,
      title: "Payment Terms",
      content: `For paid services, the following terms apply to all financial transactions.

Payment Policies:
• Non-refundable unless required by law
• Charges at current prices
• Authorized payment methods
• Current billing information required

Billing Cycles:
• Monthly or annual options
• Automatic renewals
• Pro-rated adjustments
• Currency specifications

Refund Policy:
• 30-day money-back guarantee
• Pro-rated refunds for annual plans
• Service credit options
• Dispute resolution process`
    },
    {
      icon: <FaDatabase className="w-8 h-8" />,
      title: "Content and Data",
      content: `You retain ownership of your content while granting us necessary licenses to provide services.

Content Rights:
• You maintain ownership
• Limited license to us
• Right to remove content
• Backup requirements

Content Responsibilities:
• Accuracy and legality
• Regular backups
• Copyright compliance
• Privacy considerations

Data Processing:
• GDPR compliance
• Data protection measures
• Processing limitations
• Data portability rights`
    },
    {
      icon: <FaBan className="w-8 h-8" />,
      title: "Prohibited Uses",
      content: `The following activities are strictly prohibited when using our services.

Prohibited Activities:
• Illegal actions
• Intellectual property violations
• Malware distribution
• Harassment or abuse
• Fraudulent behavior
• Service interference

Enforcement:
• Account suspension
• Service termination
• Legal action
• Damage recovery`
    },
    {
      icon: <FaExclamationTriangle className="w-8 h-8" />,
      title: "Disclaimer of Warranties",
      content: `Our service is provided "as is" without warranties of any kind.

No Warranty For:
• Uninterrupted service
• Error-free operation
• Fitness for purpose
• Data accuracy

Specific Disclaimers:
• Third-party services
• User-generated content
• External links
• Market conditions`
    },
    {
      icon: <FaBalanceScale className="w-8 h-8" />,
      title: "Limitation of Liability",
      content: `Our liability is limited to the extent permitted by law.

Liability Exclusions:
• Indirect damages
• Consequential losses
• Data loss
• Business interruption
• Third-party claims

Liability Caps:
• Limited to fees paid
• Statutory minimums
• Insurance coverage
• Force majeure events`
    },
    {
      icon: <FaGavel className="w-8 h-8" />,
      title: "Governing Law",
      content: `Legal framework governing these terms and service usage.

Jurisdiction:
• Applicable laws
• Venue selection
• Dispute resolution
• Arbitration agreements

Legal Compliance:
• Local regulations
• International laws
• Industry standards
• Regulatory requirements`
    },
    {
      icon: <FaEnvelope className="w-8 h-8" />,
      title: "Contact Information",
      content: `How to reach us regarding these Terms of Service.

Contact Methods:
• Email: legal@5starhq.com
• Phone: +1 (555) 123-4567
• Address: 123 Legal Street, San Francisco, CA 94105

Response Times:
• 24 hours for urgent matters
• 5 business days for general inquiries
• 30 days for formal requests`
    }
  ];

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="relative py-16 bg-gradient-to-b from-blue-600 to-blue-800 dark:from-blue-800 dark:to-blue-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl text-white mb-6">
              Terms of Service
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base sm:text-lg md:mt-5 md:text-xl md:max-w-3xl text-white/90 mb-12">
              Our Commitment to Fair and Transparent Service
            </p>
            <div className="flex justify-center space-x-4 mb-8">
              <Link
                to="/privacy-policy"
                className="px-6 py-3 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-colors duration-200"
              >
                Privacy Policy
              </Link>
              <Link
                to="/contact"
                className="px-6 py-3 bg-blue-700 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
              >
                Contact Support
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Quick Links */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className={`rounded-lg shadow-xl p-8 ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'}`}>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {sections.map((section, index) => (
              <a
                key={index}
                href={`#${section.title.toLowerCase().replace(/\s+/g, '-')}`}
                className={`p-4 rounded-lg ${
                  theme === 'dark'
                    ? 'hover:bg-gray-700'
                    : 'hover:bg-gray-50'
                } transition-colors duration-200 flex flex-col items-center text-center`}
              >
                <div className={`mb-2 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {section.icon}
                </div>
                <span className="text-sm font-medium">{section.title}</span>
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {sections.map((section, index) => (
            <section
              key={index}
              id={section.title.toLowerCase().replace(/\s+/g, '-')}
              className={`p-6 rounded-lg ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} shadow-lg`}
            >
              <div className="flex items-center mb-6">
                <div className={`mr-4 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {section.icon}
                </div>
                <h2 className={`text-2xl font-bold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {section.title}
                </h2>
              </div>
              <div className={`prose max-w-none ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'} whitespace-pre-line`}>
                {section.content}
              </div>
            </section>
          ))}
        </div>

        {/* Contact Section */}
        <div className={`mt-16 p-8 rounded-lg text-center ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
          <h2 className={`text-2xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Have Questions About Our Terms?
          </h2>
          <p className={`text-lg mb-8 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            Our support team is here to help clarify any aspects of our Terms of Service.
          </p>
          <div className="flex justify-center space-x-4">
            <Link
              to="/contact"
              className="px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
            >
              Contact Support
            </Link>
            <Link
              to="/privacy-policy"
              className={`px-8 py-3 rounded-lg transition-colors duration-200 ${
                theme === 'dark'
                  ? 'bg-gray-700 text-white hover:bg-gray-600'
                  : 'bg-white text-gray-900 hover:bg-gray-100'
              }`}
            >
              View Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
