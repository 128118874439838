import React, { useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FaQuoteLeft, FaStar } from 'react-icons/fa';

export default function Testimonials() {
  const { theme } = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);

  const testimonials = [
    {
      text: "5StarHQ has completely transformed how we handle our online reputation. The AI-powered response suggestions save us hours each week, and our review scores have improved significantly.",
      author: "Sarah Johnson",
      position: "Marketing Director",
      company: "Luxury Hotels Group",
      rating: 5,
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=150&q=80"
    },
    {
      text: "The automated review monitoring and instant notifications have helped us address customer concerns faster than ever. Our response time has improved by 10x!",
      author: "Michael Chen",
      position: "Owner",
      company: "The Garden Restaurant",
      rating: 5,
      image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=150&q=80"
    },
    {
      text: "As a small business owner, managing reviews was overwhelming. 5StarHQ made it simple and effective. The AI suggestions are spot-on, and our online reputation has never been better.",
      author: "Emily Rodriguez",
      position: "Founder",
      company: "Wellness Spa & Clinic",
      rating: 5,
      image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=150&q=80"
    }
  ];

  const nextTestimonial = () => {
    setActiveIndex((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setActiveIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className={`py-20 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className={`text-3xl md:text-4xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Trusted by Businesses Worldwide
          </h2>
          <p className={`text-xl ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            See what our customers have to say about their experience
          </p>
        </div>

        <div className="relative max-w-4xl mx-auto">
          {/* Navigation Buttons */}
          <button
            onClick={prevTestimonial}
            className={`absolute left-0 top-1/2 -translate-y-1/2 -translate-x-12 p-2 rounded-full ${
              theme === 'dark' ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-white text-gray-600 hover:bg-gray-100'
            } shadow-lg z-10 focus:outline-none`}
          >
            ←
          </button>
          <button
            onClick={nextTestimonial}
            className={`absolute right-0 top-1/2 -translate-y-1/2 translate-x-12 p-2 rounded-full ${
              theme === 'dark' ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-white text-gray-600 hover:bg-gray-100'
            } shadow-lg z-10 focus:outline-none`}
          >
            →
          </button>

          {/* Testimonial Card */}
          <div 
            className={`relative overflow-hidden rounded-2xl shadow-xl ${
              theme === 'dark' ? 'bg-gray-700' : 'bg-white'
            } p-8 md:p-12`}
          >
            <FaQuoteLeft className={`text-4xl mb-6 ${theme === 'dark' ? 'text-gray-500' : 'text-gray-200'}`} />
            
            <div className="flex flex-col md:flex-row items-center gap-8">
              <div className="w-24 h-24 md:w-32 md:h-32 rounded-full overflow-hidden flex-shrink-0">
                <img
                  src={testimonials[activeIndex].image}
                  alt={testimonials[activeIndex].author}
                  className="w-full h-full object-cover"
                />
              </div>
              
              <div className="flex-1">
                <div className="flex mb-4">
                  {[...Array(testimonials[activeIndex].rating)].map((_, i) => (
                    <FaStar key={i} className="text-yellow-400" />
                  ))}
                </div>
                
                <blockquote className={`text-xl md:text-2xl mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-700'}`}>
                  "{testimonials[activeIndex].text}"
                </blockquote>
                
                <div>
                  <p className={`font-semibold text-lg ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                    {testimonials[activeIndex].author}
                  </p>
                  <p className={theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}>
                    {testimonials[activeIndex].position}, {testimonials[activeIndex].company}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Dots */}
          <div className="flex justify-center mt-8 space-x-2">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setActiveIndex(index)}
                className={`w-2 h-2 rounded-full transition-all duration-200 ${
                  index === activeIndex
                    ? `w-6 ${theme === 'dark' ? 'bg-blue-400' : 'bg-blue-600'}`
                    : `${theme === 'dark' ? 'bg-gray-600' : 'bg-gray-300'}`
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
