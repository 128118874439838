import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaCheckCircle, FaSpinner } from 'react-icons/fa'
import { useTheme } from '../contexts/ThemeContext'

export default function ThankYou() {
  const navigate = useNavigate()
  const { theme } = useTheme()
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const signupData = localStorage.getItem('signupData')
    if (signupData) {
      setUserData(JSON.parse(signupData))
      localStorage.removeItem('signupData') // Clean up
    }
    setLoading(false)

    // Redirect to home after 5 seconds
    const timer = setTimeout(() => {
      navigate('/')
    }, 5000)

    return () => clearTimeout(timer)
  }, [navigate])

  if (loading) {
    return (
      <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900' : 'bg-gradient-to-br from-blue-50 via-indigo-50 to-white'} flex items-center justify-center`}>
        <FaSpinner className="animate-spin h-8 w-8 text-blue-600" />
      </div>
    )
  }

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900' : 'bg-gradient-to-br from-blue-50 via-indigo-50 to-white'} py-24`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-2xl shadow-xl p-8 md:p-12`}>
            <div className="flex justify-center mb-8">
              <FaCheckCircle className="h-16 w-16 text-green-500" />
            </div>
            
            <h1 className={`text-4xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
              Thank You{userData?.name ? `, ${userData.name}` : ''}!
            </h1>
            
            <p className={`text-xl mb-8 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
              Your journey to a 5-star reputation starts now
            </p>

            <div className={`text-lg ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'} space-y-4`}>
              <p>
                We've sent a confirmation email to{' '}
                <span className="font-semibold">{userData?.email || 'your email address'}</span>
              </p>
              <p>
                Our team will be in touch shortly to help you get started
              </p>
              <p className="text-sm mt-8">
                You'll be redirected to the homepage in a few seconds...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
