import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import blogPosts from '../data/blogPosts';
import BlogCard from '../components/BlogCard';
import { Helmet } from 'react-helmet';

export default function BlogTag() {
  const { tag } = useParams();
  const { theme } = useTheme();
  
  // Convert URL-friendly tag back to original format
  const decodedTag = tag.split('-').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1)
  ).join(' ');
  
  // Filter posts by tag
  const filteredPosts = blogPosts.filter(post =>
    post.tags.some(t => t.toLowerCase() === decodedTag.toLowerCase())
  );

  return (
    <div className={`min-h-screen py-16 ${
      theme === 'dark' ? 'bg-gray-900' : 'bg-gradient-to-br from-blue-50 via-indigo-50 to-white'
    }`}>
      <Helmet>
        <title>{`${decodedTag} Blog Posts | 5StarHQ`}</title>
        <meta 
          name="description" 
          content={`Read our blog posts about ${decodedTag.toLowerCase()} and learn more about online reputation management.`}
        />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className={`text-4xl font-bold mb-4 ${
            theme === 'dark' ? 'text-white' : 'text-gray-900'
          }`}>
            Posts Tagged: {decodedTag}
          </h1>
          <p className={`text-lg ${
            theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
          }`}>
            {filteredPosts.length} {filteredPosts.length === 1 ? 'post' : 'posts'} found
          </p>
        </div>

        {filteredPosts.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredPosts.map((post) => (
              <BlogCard key={post.id} {...post} />
            ))}
          </div>
        ) : (
          <div className="text-center py-16">
            <p className={`text-xl ${
              theme === 'dark' ? 'text-gray-400' : 'text-gray-600'
            }`}>
              No posts found with tag: {decodedTag}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
