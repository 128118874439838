import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FaUsers, FaComments, FaDiscord, FaSlack } from 'react-icons/fa';

const Community = () => {
  const { theme } = useTheme();

  const communityChannels = [
    {
      icon: <FaDiscord className="text-3xl" />,
      title: "Discord Community",
      description: "Join our Discord server to connect with other users, share tips, and get help.",
      action: "Join Discord",
      link: "https://discord.gg/5starhq"
    },
    {
      icon: <FaSlack className="text-3xl" />,
      title: "Slack Workspace",
      description: "Connect with our team and other users in our Slack workspace.",
      action: "Join Slack",
      link: "https://5starhq.slack.com"
    },
    {
      icon: <FaComments className="text-3xl" />,
      title: "Discussion Forum",
      description: "Participate in discussions, share your experience, and learn from others.",
      action: "View Forum",
      link: "/forum"
    },
    {
      icon: <FaUsers className="text-3xl" />,
      title: "User Groups",
      description: "Find and join user groups in your area or industry.",
      action: "Find Groups",
      link: "/groups"
    }
  ];

  const upcomingEvents = [
    {
      title: "Monthly User Meetup",
      date: "Last Thursday of every month",
      description: "Join our virtual meetup to connect with other users and share experiences.",
      link: "/events/monthly-meetup"
    },
    {
      title: "Product Workshop",
      date: "Every Tuesday",
      description: "Learn how to get the most out of 5StarHQ with our product experts.",
      link: "/events/workshop"
    },
    {
      title: "Industry Roundtable",
      date: "First Wednesday of every month",
      description: "Discuss industry-specific challenges and solutions with peers.",
      link: "/events/roundtable"
    }
  ];

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="relative py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className={`text-4xl md:text-5xl font-bold mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Join Our Community
          </h1>
          <p className={`text-xl mb-8 max-w-3xl mx-auto ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            Connect with other 5StarHQ users, share experiences, and learn from each other.
          </p>
        </div>
      </div>

      {/* Community Channels */}
      <div className={`py-16 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {communityChannels.map((channel, index) => (
              <div
                key={index}
                className={`p-6 rounded-lg ${
                  theme === 'dark' ? 'bg-gray-700' : 'bg-white'
                } flex flex-col items-center text-center`}
              >
                <div className={`mb-4 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {channel.icon}
                </div>
                <h3 className={`text-xl font-semibold mb-2 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {channel.title}
                </h3>
                <p className={`mb-6 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                  {channel.description}
                </p>
                <a
                  href={channel.link}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                >
                  {channel.action}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Upcoming Events */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-3xl font-bold text-center mb-12 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Upcoming Community Events
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {upcomingEvents.map((event, index) => (
              <div
                key={index}
                className={`p-6 rounded-lg ${
                  theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'
                }`}
              >
                <h3 className={`text-xl font-semibold mb-2 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {event.title}
                </h3>
                <p className={`text-sm mb-4 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {event.date}
                </p>
                <p className={`mb-6 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                  {event.description}
                </p>
                <a
                  href={event.link}
                  className="text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  Learn more →
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
