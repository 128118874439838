import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';

export default function BlogCard({ id, title, description, image, category, date, readTime }) {
  const { theme } = useTheme();
  
  return (
    <Link 
      to={`/blog/${id}`} 
      className={`block overflow-hidden rounded-lg shadow-lg transform hover:scale-[1.02] transition-all duration-300 ${
        theme === 'dark' ? 'bg-gray-800 hover:shadow-blue-500/20' : 'bg-white hover:shadow-xl'
      }`}
    >
      <div className="aspect-video bg-gray-100 dark:bg-gray-700 relative overflow-hidden">
        <img 
          src={image} 
          alt={title}
          className="object-cover w-full h-full transform hover:scale-105 transition-transform duration-300"
        />
        <span className="absolute top-4 left-4 bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
          {category}
        </span>
      </div>
      <div className="p-6">
        <h3 className={`text-xl font-semibold mb-2 group-hover:text-blue-600 transition-colors ${
          theme === 'dark' ? 'text-white' : 'text-gray-900'
        }`}>
          {title}
        </h3>
        <p className={`mb-4 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
          {description}
        </p>
        <div className="flex items-center justify-between text-sm">
          <span className={theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}>
            {date}
          </span>
          <span className={theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}>
            {readTime} min read
          </span>
        </div>
      </div>
    </Link>
  );
}
