import { OpenAI } from 'openai';

export const POLICY_COLORS = {
  'Professional Conduct': 'orange',
  'Customer Experience': 'sky',
  'Content Relevance': 'lime',
  'Personal Attack': 'yellow',
  'Off-Topic Content': 'cyan',
  'Harassment': 'amber',
  'Business Misrepresentation': 'rose',
  'Authenticity': 'violet',
  'Quality Standards': 'emerald',
  'Compliance': 'green',
  'Policy Violation': 'fuchsia'
};

function createOpenAIClient() {
  const apiKey = import.meta.env.VITE_OPENAI_API_KEY;
  
  if (!apiKey) {
    throw new Error('OpenAI API key is not configured. Please add VITE_OPENAI_API_KEY to your .env file');
  }

  return new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true
  });
}

export async function analyzeReviewWithAI(text) {
  if (!text?.trim()) return [];

  try {
    const openai = createOpenAIClient();

    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `You are a Google review policy expert. For each policy category, analyze whether the content passes or fails, providing specific evidence for both compliant and non-compliant sections. Use these categories:
- Professional Conduct
- Customer Experience
- Content Relevance
- Personal Attack
- Off-Topic Content
- Harassment
- Business Misrepresentation
- Authenticity
- Quality Standards`
        },
        {
          role: "user",
          content: `Analyze this review for both policy compliance and violations. For each section analyzed, indicate pass/fail status and provide evidence. Return in this exact JSON structure:
{
  "results": [
    {
      "name": "Policy Category Name",
      "passed": boolean,
      "description": "Detailed analysis",
      "severity": "high" | "medium" | "low",
      "highlights": [
        {
          "text": "exact text",
          "startIndex": number,
          "endIndex": number,
          "type": "policy category",
          "isPassed": boolean,
          "evidence": "why this passes/fails"
        }
      ],
      "removalReasons": [
        {
          "perspective": "Analysis angle",
          "policy": "Specific policy",
          "justification": "Detailed explanation",
          "violatingText": "Quoted text",
          "policyReference": "Section reference"
        }
      ]
    }
  ]
}

Review to analyze: "${text.replace(/"/g, '\\"')}"`
        }
      ],
      temperature: 0,
      max_tokens: 2000,
      response_format: { type: "json_object" }
    });

    const aiAnalysis = JSON.parse(response.choices[0].message?.content || "{}");
    
    if (!aiAnalysis?.results?.length) {
      return [{
        name: 'Analysis Error',
        passed: false,
        description: 'No analysis results were returned',
        severity: 'high',
        highlights: [],
        removalReasons: [{
          perspective: 'System Error',
          policy: 'Analysis Failed',
          justification: 'The analysis service did not return any results',
          violatingText: '',
          policyReference: 'N/A'
        }]
      }];
    }

    return aiAnalysis.results.map(result => ({
      name: validatePolicyCategory(result.name),
      passed: Boolean(result.passed),
      description: String(result.description || ''),
      severity: validateSeverity(result.severity),
      highlights: validateHighlights(result.highlights || []),
      removalReasons: validateRemovalReasons(result.removalReasons || [])
    }));

  } catch (error) {
    console.error('AI Analysis Error:', error);
    
    let errorMessage = 'An unknown error occurred';
    
    if (error instanceof Error) {
      if (error.message.includes('API key')) {
        errorMessage = 'API key is invalid or not configured properly';
      } else if (error.message.includes('401')) {
        errorMessage = 'Authentication failed. Please check your API key';
      } else if (error.message.includes('429')) {
        errorMessage = 'Rate limit exceeded. Please try again later';
      } else {
        errorMessage = error.message;
      }
    }

    return [{
      name: 'System Error',
      passed: false,
      description: errorMessage,
      severity: 'high',
      highlights: [],
      removalReasons: [{
        perspective: 'Error Analysis',
        policy: 'System Error',
        justification: errorMessage,
        violatingText: '',
        policyReference: 'N/A'
      }]
    }];
  }
}

function validatePolicyCategory(category) {
  return Object.keys(POLICY_COLORS).find(
    c => c.toLowerCase() === category?.toLowerCase()
  ) || 'Policy Violation';
}

function validateSeverity(severity) {
  return ['high', 'medium', 'low'].includes(severity) ? severity : "medium";
}

function validateHighlights(highlights) {
  if (!Array.isArray(highlights)) return [];
  
  return highlights
    .filter(h => h && typeof h === 'object')
    .map(h => ({
      text: String(h.text || ''),
      startIndex: Number(h.startIndex) || 0,
      endIndex: Number(h.endIndex) || 0,
      type: validatePolicyCategory(h.type),
      isPassed: Boolean(h.isPassed),
      evidence: String(h.evidence || '')
    }))
    .filter(h => h.text && h.endIndex >= h.startIndex);
}

function validateRemovalReasons(reasons) {
  if (!Array.isArray(reasons)) return [];

  return reasons.map(r => ({
    perspective: String(r?.perspective || ''),
    policy: String(r?.policy || ''),
    justification: String(r?.justification || ''),
    violatingText: String(r?.violatingText || ''),
    policyReference: String(r?.policyReference || '')
  }));
}

export function getTextSegments(text, results) {
  const allHighlights = results.flatMap(result => 
    result.highlights.map(h => ({
      ...h,
      policyCategory: result.name,
      isPassed: result.passed
    }))
  );

  const sortedHighlights = [...allHighlights].sort((a, b) => a.startIndex - b.startIndex);
  const segments = [];
  let lastIndex = 0;

  sortedHighlights.forEach(highlight => {
    if (highlight.startIndex > lastIndex) {
      segments.push({
        text: text.slice(lastIndex, highlight.startIndex),
        isHighlighted: false,
        policyCategory: 'Compliance',
        isPassed: true
      });
    }

    segments.push({
      text: text.slice(highlight.startIndex, highlight.endIndex),
      isHighlighted: true,
      policyCategory: highlight.type,
      isPassed: highlight.isPassed,
      evidence: highlight.evidence
    });

    lastIndex = highlight.endIndex;
  });

  if (lastIndex < text.length) {
    segments.push({
      text: text.slice(lastIndex),
      isHighlighted: false,
      policyCategory: 'Compliance',
      isPassed: true
    });
  }

  return segments;
}

export const utils = {
  validatePolicyCategory,
  validateSeverity,
  validateHighlights,
  validateRemovalReasons
};
