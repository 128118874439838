import React from 'react';
import { useTheme } from '../contexts/ThemeContext';

export default function Logo({ className = '' }) {
  const { theme } = useTheme();
  
  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      <img src="/5starhq-logo.svg" alt="5StarHQ Logo" className="h-8 w-8" />
      <div className="flex items-center">
        <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600">
          5Star
        </span>
        <div className="relative">
          <span className={`text-2xl font-bold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            HQ
          </span>
          <span className="absolute -top-1 -right-3 text-yellow-400">★</span>
        </div>
      </div>
    </div>
  );
}
