import React from 'react';
import { useParams } from 'react-router-dom';
import BlogPostComponent from '../components/BlogPost';
import blogPosts from '../data/blogPosts';

export default function BlogPost() {
  const { id } = useParams();
  const post = blogPosts.find(post => post.id === id);

  if (!post) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">Blog Post Not Found</h1>
          <p className="text-gray-600 dark:text-gray-300">
            The blog post you're looking for doesn't exist.
          </p>
        </div>
      </div>
    );
  }

  return <BlogPostComponent {...post} blogPosts={blogPosts} />;
}
