import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FaMoneyBillWave, FaChartLine, FaHandshake, FaRocket } from 'react-icons/fa';

const Affiliates = () => {
  const { theme } = useTheme();

  const benefits = [
    {
      icon: <FaMoneyBillWave className="text-3xl" />,
      title: "High Commissions",
      description: "Earn up to 30% commission on every referral that converts to a paid subscription."
    },
    {
      icon: <FaChartLine className="text-3xl" />,
      title: "Recurring Revenue",
      description: "Get paid monthly for as long as your referrals remain active customers."
    },
    {
      icon: <FaHandshake className="text-3xl" />,
      title: "Partner Support",
      description: "Access marketing materials, dedicated support, and regular performance insights."
    },
    {
      icon: <FaRocket className="text-3xl" />,
      title: "Instant Payouts",
      description: "Get paid automatically every month when you reach the minimum threshold."
    }
  ];

  const tiers = [
    {
      name: "Bronze",
      sales: "0-10",
      commission: "20%",
      benefits: [
        "Basic marketing materials",
        "Monthly payments",
        "Email support",
        "Performance tracking"
      ]
    },
    {
      name: "Silver",
      sales: "11-50",
      commission: "25%",
      benefits: [
        "Advanced marketing kit",
        "Priority support",
        "Custom landing pages",
        "Quarterly strategy calls"
      ]
    },
    {
      name: "Gold",
      sales: "51+",
      commission: "30%",
      benefits: [
        "Premium resources",
        "Dedicated account manager",
        "Co-marketing opportunities",
        "Early access to features"
      ]
    }
  ];

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="relative py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className={`text-4xl md:text-5xl font-bold mb-6 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Join Our Affiliate Program
          </h1>
          <p className={`text-xl mb-8 max-w-3xl mx-auto ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            Partner with 5StarHQ and earn generous commissions by helping businesses improve their online reputation.
          </p>
          <button className="px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200">
            Become an Affiliate
          </button>
        </div>
      </div>

      {/* Benefits Section */}
      <div className={`py-16 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-3xl font-bold text-center mb-12 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Why Partner With Us?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <div
                key={index}
                className={`p-6 rounded-lg ${
                  theme === 'dark' ? 'bg-gray-700' : 'bg-white'
                } flex flex-col items-center text-center`}
              >
                <div className={`mb-4 ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                  {benefit.icon}
                </div>
                <h3 className={`text-xl font-semibold mb-2 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                  {benefit.title}
                </h3>
                <p className={`${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Commission Tiers */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className={`text-3xl font-bold text-center mb-12 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Commission Tiers
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {tiers.map((tier, index) => (
              <div
                key={index}
                className={`rounded-lg overflow-hidden ${
                  theme === 'dark' ? 'bg-gray-800' : 'bg-white'
                } shadow-lg`}
              >
                <div className={`px-6 py-8 ${
                  theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50'
                }`}>
                  <h3 className={`text-2xl font-bold text-center ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                    {tier.name}
                  </h3>
                  <p className={`text-center mt-2 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                    {tier.sales} sales per month
                  </p>
                  <p className="text-center mt-4">
                    <span className={`text-4xl font-bold ${theme === 'dark' ? 'text-blue-400' : 'text-blue-600'}`}>
                      {tier.commission}
                    </span>
                  </p>
                </div>
                <div className="px-6 py-8">
                  <ul className="space-y-4">
                    {tier.benefits.map((benefit, benefitIndex) => (
                      <li
                        key={benefitIndex}
                        className={`flex items-center ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}
                      >
                        <svg className="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className={`py-16 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className={`text-3xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Ready to Start Earning?
          </h2>
          <p className={`text-xl mb-8 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
            Join our affiliate program today and start earning recurring commissions.
          </p>
          <button className="px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200">
            Apply Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Affiliates;
