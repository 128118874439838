import React, { useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FaSearch, FaSpinner, FaExclamationTriangle, FaCheckCircle } from 'react-icons/fa';
import { analyzeReviewWithAI, POLICY_COLORS } from '../lib/ai-checker';

const getColorClasses = (colorName, isDark) => {
  const baseColors = {
    orange: {
      light: {
        bg: 'bg-orange-50',
        border: 'border-orange-200',
        text: 'text-orange-800',
        badge: 'bg-orange-100 text-orange-800',
        darkBadge: 'bg-orange-800 text-orange-200'
      },
      dark: {
        bg: 'bg-orange-900/20',
        border: 'border-orange-800',
        text: 'text-orange-300',
        badge: 'bg-orange-800 text-orange-200'
      }
    },
    sky: {
      light: {
        bg: 'bg-sky-50',
        border: 'border-sky-200',
        text: 'text-sky-800',
        badge: 'bg-sky-100 text-sky-800',
        darkBadge: 'bg-sky-800 text-sky-200'
      },
      dark: {
        bg: 'bg-sky-900/20',
        border: 'border-sky-800',
        text: 'text-sky-300',
        badge: 'bg-sky-800 text-sky-200'
      }
    },
    gray: {
      light: {
        bg: 'bg-gray-50',
        border: 'border-gray-200',
        text: 'text-gray-800',
        badge: 'bg-gray-100 text-gray-800',
        darkBadge: 'bg-gray-800 text-gray-200'
      },
      dark: {
        bg: 'bg-gray-900/20',
        border: 'border-gray-800',
        text: 'text-gray-300',
        badge: 'bg-gray-800 text-gray-200'
      }
    }
  };

  const colors = baseColors[colorName] || baseColors.gray;
  return isDark ? colors.dark : colors.light;
};

export default function ReviewChecker() {
  const { theme } = useTheme();
  const isDark = theme === 'dark';
  const [reviewText, setReviewText] = useState('');
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const results = await analyzeReviewWithAI(reviewText);
      const isCompliant = results.every(result => result.passed);
      const violations = results
        .filter(result => !result.passed)
        .map(result => ({
          policy: result.name,
          explanation: result.description,
          segment: result.highlights?.[0]?.text,
          severity: result.severity,
          color: POLICY_COLORS[result.name] || 'gray'
        }));
      
      setAnalysis({
        compliant: isCompliant,
        violations,
        suggestions: results
          .filter(result => !result.passed)
          .flatMap(result => 
            result.removalReasons?.map(reason => reason.justification) || []
          )
      });
    } catch (err) {
      setError(err.message || 'Failed to analyze review');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-900 dark:text-white text-center mb-4 font-poppins">
            AI Review Policy Checker
          </h1>
          <p className="text-center text-xl text-gray-600 dark:text-gray-300 mb-12 font-inter">
            Check if your review complies with Google's review policies using AI
          </p>

          <form onSubmit={handleSubmit} className="mb-12">
            <div className="space-y-4">
              <textarea
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
                placeholder="Paste your review text here..."
                className={`w-full h-40 px-4 py-3 rounded-lg border font-inter ${
                  isDark 
                    ? 'bg-gray-800 border-gray-700 text-white placeholder-gray-400' 
                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                } focus:ring-2 focus:ring-blue-500 focus:border-blue-500`}
                required
              />
              <button
                type="submit"
                disabled={loading || !reviewText.trim()}
                className={`w-full px-6 py-3 rounded-lg font-medium font-inter
                         ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'} 
                         bg-blue-600 text-white focus:outline-none focus:ring-2 
                         focus:ring-blue-500 focus:ring-offset-2 
                         transition-colors duration-200
                         flex items-center justify-center space-x-2`}
              >
                {loading ? (
                  <>
                    <FaSpinner className="animate-spin h-5 w-5" />
                    <span>Analyzing...</span>
                  </>
                ) : (
                  <>
                    <FaSearch className="h-5 w-5" />
                    <span>Check Review</span>
                  </>
                )}
              </button>
            </div>
          </form>

          {error && (
            <div className={`rounded-lg p-4 mb-8 ${
              isDark 
                ? 'bg-red-900/20 border border-red-800' 
                : 'bg-red-50 border border-red-200'
            }`}>
              <div className={`flex items-center ${
                isDark ? 'text-red-400' : 'text-red-800'
              } font-inter`}>
                <FaExclamationTriangle className="h-5 w-5 mr-2" />
                <span>{error}</span>
              </div>
            </div>
          )}

          {analysis && (
            <div className={`rounded-lg shadow-lg p-6 space-y-6 ${
              isDark ? 'bg-gray-800' : 'bg-white'
            }`}>
              {/* Overall Compliance Status */}
              <div className={`flex items-center justify-between p-4 rounded-lg ${
                isDark ? 'bg-gray-700' : 'bg-gray-50'
              }`}>
                <h3 className={`text-lg font-semibold ${
                  isDark ? 'text-white' : 'text-gray-900'
                } font-poppins`}>
                  Policy Compliance
                </h3>
                <div className={`flex items-center font-inter ${
                  analysis.compliant 
                    ? isDark ? 'text-green-400' : 'text-green-600'
                    : isDark ? 'text-red-400' : 'text-red-600'
                }`}>
                  {analysis.compliant ? (
                    <>
                      <FaCheckCircle className="h-5 w-5 mr-2" />
                      <span>Compliant</span>
                    </>
                  ) : (
                    <>
                      <FaExclamationTriangle className="h-5 w-5 mr-2" />
                      <span>Non-Compliant</span>
                    </>
                  )}
                </div>
              </div>

              {/* Policy Violations */}
              {!analysis.compliant && (
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white font-poppins">
                    Policy Violations
                  </h3>
                  <div className="space-y-4">
                    {analysis.violations.map((violation, index) => {
                      const colors = getColorClasses(violation.color, isDark);
                      return (
                        <div
                          key={index}
                          className={`rounded-lg p-4 border ${colors.bg} ${colors.border}`}
                        >
                          <div className="flex flex-col space-y-2">
                            <div className="flex items-center justify-between">
                              <span className={`font-medium ${colors.text} font-poppins`}>
                                {violation.policy}
                              </span>
                              <span className={`px-2 py-1 rounded text-sm font-medium ${colors.badge} font-inter`}>
                                {violation.severity}
                              </span>
                            </div>
                            <p className={`${colors.text} font-inter`}>{violation.explanation}</p>
                            {violation.segment && (
                              <div className={`mt-2 p-3 rounded ${isDark ? 'bg-gray-900/50' : 'bg-gray-50'} font-inter`}>
                                <p className={`italic ${colors.text}`}>"{violation.segment}"</p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {/* Suggestions */}
              {!analysis.compliant && analysis.suggestions.length > 0 && (
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white font-poppins">
                    Suggested Improvements
                  </h3>
                  <ul className="list-disc pl-5 space-y-2">
                    {analysis.suggestions.map((suggestion, index) => (
                      <li key={index} className={`${
                        isDark ? 'text-gray-300' : 'text-gray-700'
                      } font-inter`}>
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
